import {useState, useEffect} from 'react';
import {useAddToHomescreenPrompt, useLabel} from '@/hooks/export';
import {UiButton} from '@/components/export';
import utils from 'utils';
import appIcon from '@img/app_icon_256.png';

export const PwaInstallPrompt = () => {
    const [prompt, promptToInstall] = useAddToHomescreenPrompt();
    const [isVisible, setVisibleState] = useState(false);
    const label = useLabel();
  
    const hide = () => setVisibleState(false);

    useEffect(() => {
        if (prompt) {
            setVisibleState(true);
        }
    }, [prompt]);

    if (isVisible) {
        return (
            <div className={classMap.container}>
                <div className={cmpClass}>
                    <img src={appIcon} alt="logo" className={classMap.icon} />
                    {label.installApp}
                    
                    <UiButton 
                        style={1}
                        text={label.installBtn}
                        onClick={promptToInstall} 
                    />
                    <span className={classMap.iconClose} onClick={hide}></span>
                </div>
            </div>
        );
    }

    return null;
}

const cmpClass = 'install-prompt';
const classMap = utils.generateClassMap(cmpClass, {
    container: 'container container',
    iconClose: 'close-btn iconfa-close',
    icon: 'icon',
});

