import {api, useApiQuery} from '@/api';
import utils from 'utils';

import {useState} from 'react';

import {useLabel} from '@/hooks/export';

export const useCart = (props?: {
    enabled?: boolean,
}) => {
    const label = useLabel();
    const query = useApiQuery('getCart', {}, !props?.enabled);

    const cart : void | apiCart = query.data;

    const [productOptionsImages, setProductOptionsImages] = useState([]); // MachineRequestAttachment type

    const [contactForm, setContactForm] = useState({
        contactEmail: '',
        contactFirstName: '',
        contactLastName: '',
        contactPhone: '',
        doSaveAddress: false,
        doSaveContact: false,
        execCity: '',
        execCompany: '',
        execPostalCode: '',
        execStreet: '',
        info2: '',
        info: label.billingCompanyByUserBranch,
        invoiceCity: '',
        invoiceCompany: '',
        invoicePostalCode: '',
        invoiceStreet: '',
    });

    const addProductOptionsImage = (productId: string, attachment: machineRequestAttachment) => {
        if (!Array.isArray(productOptionsImages[productId])) {
            productOptionsImages[productId] = [];
        }

        productOptionsImages[productId].push(attachment);

        setProductOptionsImages({...productOptionsImages});
    };

    return {
        query,
        cart,
        updateItem,
        updateNote,
        clean: () => api.cart.clean(),
        submit: async () => {
            const productOptionsImagesBase64 = {};

            for (const [key, values] of Object.entries(productOptionsImages)) {
                productOptionsImagesBase64[key] = await Promise.all(
                    values.map(async (item) => {
                        const base64File = await utils.fileToBase64(item.file);
                        return {
                            ...item,
                            file: base64File,
                        };
                    })
                );
            }

            return api.cart.submit({
                products: (cart as unknown as apiCart).list, // fixme - temp fix
                contact: {
                    email: contactForm.contactEmail,
                    firstName: contactForm.contactFirstName,
                    lastName: contactForm.contactLastName,
                    phone: contactForm.contactPhone,
                },
                invoiceAddress: {
                    city: contactForm.invoiceCity,
                    company: contactForm.invoiceCompany,
                    postalCode: contactForm.invoicePostalCode,
                    street: contactForm.invoiceStreet,
                },
                execAddress: {
                    city: contactForm.execCity,
                    company: contactForm.execCompany,
                    postalCode: contactForm.execPostalCode,
                    street: contactForm.execStreet,
                },
                productOptionsImages: productOptionsImagesBase64,
            });
        },
        saved: {
            execAddresses: useApiQuery('getSavedAddresses', {}, !props?.enabled).data, // todo: add types
            contacts: useApiQuery('getSavedContacts', {}, !props?.enabled).data,
        },
        contactForm,
        setContactForm,
        productOptionsImages,
        setProductOptionsImages,
        addProductOptionsImage,
        flags: {
            isMontaz: (cart as unknown as apiCart)?.flags?.isMontaz,
            isMontazVzdusnice: (cart as unknown as apiCart)?.flags?.isMontazVzdusnice,
            isVidlice: (cart as unknown as apiCart)?.flags?.isVidlice,
            isSelfPickup: (cart as unknown as apiCart)?.flags?.isSelfPickup,
            isOrderAllowed: (cart as unknown as apiCart)?.flags?.isOrderAllowed,
        },
    };
};

const updateItem = (
    id: string,
    qty: number,
    additionalServices: [additionalService],
    additionalInfo?: {
        manufacturerNum: string,
        internalNum: string,
        note: string,
        execDate: string, // todo: check date format?
    },
) => {
    // todo: clear execDate if services total qty = 0

    return api.cart.update({
        productId: id,
        qty: qty,
        additionalServices: additionalServices,
        additionalInfo: additionalInfo || {},
    });
};

const updateNote = (note: string) => {
    return api.cart.updateNote({
        note,
    });
};
