import utils from 'utils';

import {useContext, useEffect} from 'react';

import appContext from '@/appContext.tsx';

import {
    CatalogFilters,
    CatalogInfo,
    CatalogMenu,
    Footer,
    Header,
    ProductsDisplayManager,
} from '@components/export';

import {Skeleton} from '@mui/material';

export const CatalogPage = () => {
    // todo: pass render data as props

    const context = useContext(appContext).catalogContext;

    const options = context.menu;
    const query = context.query;

    const {filters} = useContext(appContext).productsContext;

    const data = {
        purpose: filters.purpose,
    };

    useEffect(() => {
        if (filters.purpose !== undefined && filters.offset !== undefined) {
            window.history.replaceState(null, null, utils.convertFiltersToUrl(filters));
        }
    }, [filters]);

    return <>
        <Header/>

        <div className="page-content catalog-page">
            <CatalogMenu
                options={query.isFetched && Array.isArray(options) ? options : [{name: '', label: ''}]}
                initPurpose={data.purpose}
            />
            {
                query.isFetched && Array.isArray(options) ?
                    <>
                        <div className="catalog-page__container container">
                            <CatalogFilters
                                purpose={data.purpose}
                            />
                            <ProductsDisplayManager
                                limit={12}
                                isDemo={false}
                                mode={'catalog'}
                                purpose={data.purpose}
                                useFilters={true}
                                initFilters={utils.parseUrlToFilters()}
                            />
                        </div>

                        <CatalogInfo/>
                    </> :
                    <Skeleton width={'100%'} height={'200px'}/>
            }
        </div>

        <Footer/>
    </>;
};
