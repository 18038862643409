import {useNavigate} from 'react-router-dom';

export const HeaderNavMenu = () => {
    const navigate = useNavigate();

    // todo: get from api
    const data = {
        links: [
            {title: 'Produkty', location: '/catalog'},
            // {title: 'Novinky', location: '/welcome'},
            {title: 'Kontakty', location: '/wiki/obchodni-oddeleni'},
            {title: 'Poptávka', location: '/machine-request'},
        ],
    };

    return <>
        {
            data.links.map((link) => {
                return <a onClick={() => navigate(link.location)} key={link.title}>
                    {link.title}
                </a>;
            })
        }
    </>;
};
