import utils from 'utils';
import {api, useApiQuery} from '@/api';

import {useEffect, useState, useRef} from 'react';
import {useNavigate} from 'react-router-dom';

import {BigTitle, UiButton, UiSelect, UiPhoneInput} from '@components/export';
import {useEshopConfig, useLabel} from '@/hooks/export';

import {TextField} from '@mui/material';
import toast from 'react-hot-toast';
import {isValidPhoneNumber} from 'libphonenumber-js';

export const UserRegister = () => {
    const {layout} = useEshopConfig().userRegisterForm;
    const label = useLabel();
    const navigate = useNavigate();

    const optionEnums = useApiQuery('getRegisterFormEnums', {}).data;

    const [notValidFields, setNotValidFields] = useState([]);
    const notValidFieldsRef = useRef([]);

    const [fields, setFields] = useState({
        companyIco: '',
        companyName: '',
        companyEmail: '',
        companyPhone: '',
        invoiceCity: '',
        invoiceStreet: '',
        invoicePostalCode: '',
        invoiceCountry: '',
        contactFirstName: '',
        contactLastName: '',
        contactPhone: '',
        contactEmail: '',
        contactJobTitle: '',
    });

    const handle = {
        getAccountByICO(ico) {
            api.company.checkIco(ico)
                .then((response) => {
                    if (!response.companyName) {
                        return;
                    }

                    this.inputChange('companyName', response.companyName || '');
                    this.validateInput('companyName', response.companyName || '', 'inputText');

                    this.inputChange('invoiceStreet', response.invoiceStreet || '');
                    this.validateInput('invoiceStreet', response.invoiceStreet || '', 'inputText');

                    this.inputChange('invoicePostalCode', response.invoicePostalCode || '');
                    this.validateInput('invoicePostalCode', response.invoicePostalCode || '', 'inputText');

                    this.inputChange('invoiceCity', response.invoiceCity || '');
                    this.validateInput('invoiceCity', response.invoiceCity || '', 'inputText');
                });
        },
        inputChange(name: string, value: string) {
            fields[name] = value;

            if (name === 'invoiceCountry') {
                fields.companyPhone = setPhonePrefixByCountry(value, fields.companyPhone);
                fields.contactPhone = setPhonePrefixByCountry(value, fields.contactPhone);
            }

            if (name === 'companyIco' && fields[name].length === 8) {
                this.getAccountByICO(fields[name]);
            }

            setFields({...fields});
        },
        userRegister() {
            handle.validateForm();

            if (notValidFieldsRef.current.length) {
                toast.error(label.requiredField);
                return;
            } else {
                return api.user.register(fields)
                    .then((ret) => {
                        if (!ret?.status) {
                            toast.error(label[ret?.message]);
                            return;
                        }
                        toast.success(label.accepted);
                        navigate('/login');
                    });
            }
        },
        validateForm() {
            // check all required fields
            for (const group of layout) {
                for (const fieldData of group.grid) {
                    if (fieldData.required) {
                        this.validateInput(
                            fieldData.name,
                            fields[fieldData.name],
                            fieldData.type,
                        );
                    }
                }
            }
        },
        validateInput(name, value, type) {
            let isValid = !!value;

            switch (type) {
                case 'inputEmail':
                    isValid = validateEmail(value) || false;

                    break;
                case 'inputPhone':
                    isValid = isValidPhoneNumber(value) || false;

                    break;
            }

            const fields = isValid ?
                notValidFieldsRef.current.filter((item) => item !== name) :
                notValidFieldsRef.current.includes(name) ?
                    notValidFieldsRef.current :
                    [
                        ...notValidFieldsRef.current,
                        name,
                    ];

            notValidFieldsRef.current = fields;

            setNotValidFields(fields);
        },
    };

    useEffect(() => {
        handle.inputChange('invoiceCountry', 'CZECH REPUBLIC');
        handle.validateInput('invoiceCountry', 'CZECH REPUBLIC', 'inputText');
    }, []);

    useEffect(() => {
        notValidFieldsRef.current = notValidFields;
    }, [notValidFields]);

    return <div className={cmpClass}>
        <BigTitle title={label.accessToB2B} dark={true} className={''} />
        {
            optionEnums !== undefined && layout.map((group, groupI) => {
                return <div className={classMap.group} key={groupI}>
                    <p className={classMap.title}>
                        {group.title}
                    </p>

                    <div className={classMap.grid}>
                        {
                            group.grid.map((item, itemI) => {
                                return <div className={classMap.gridItem} key={itemI}>
                                    <div className={classMap[item.type]}>
                                        {
                                            getGridItemJSX(
                                                item,
                                                ({name, value}) => {
                                                    handle.inputChange(name, value);

                                                    if (notValidFields.includes(name)) {
                                                        handle.validateInput(item.name, fields[item.name], item.type);
                                                    }
                                                },
                                                () => {
                                                    handle.validateInput(item.name, fields[item.name], item.type);
                                                },
                                                fields,
                                                notValidFields.includes(item.name),
                                                optionEnums,
                                            )
                                        }
                                    </div>
                                </div>;
                            })
                        }
                    </div>
                </div>;
            })
        }
        <div className={classMap.button}>
            <UiButton
                style={1}
                text={label.sendRequest}
                onClick={() => handle.userRegister()}
                // loading={loading}
            />
        </div>
    </div>;
};

const cmpClass = 'user-register';
const classMap = utils.generateClassMap(cmpClass, {
    title: 'title',
    box: 'box',
    fields: 'fields',
    field: 'field',
    action: 'action',
    button: 'button',
    link: 'link',
    request: 'request',
    grid: 'grid',
});

// todo: move to new component -> FormElement
const getGridItemJSX = (item, onChange, onBlur, fields, error, optionEnums) => {
    let options = [];

    switch (item.type) {
        case 'inputText':
            return <TextField
                name={item.name}
                label={item.label}
                size={'small'}
                value={fields[item.name]}
                fullWidth
                onChange={(e) => {
                    onChange({
                        name: item.name,
                        value: e.target.value,
                    });
                }}
                onBlur={() => onBlur()}
                required={item.required}
                error={error}
            />;
        case 'inputEmail':
            return <TextField
                type={'email'}
                name={item.name}
                label={item.label}
                size={'small'}
                value={fields[item.name]}
                fullWidth
                onChange={(e) => {
                    onChange({
                        name: item.name,
                        value: e.target.value,
                    });
                }}
                onBlur={() => onBlur()}
                required={item.required}
                error={error}
            />;
        case 'inputPhone':
            return <UiPhoneInput
                name={item.name}
                label={item.label}
                value={fields[item.name]}
                onChange={(value) => {
                    onChange({
                        name: item.name,
                        value: value,
                    });
                }}
                onBlur={() => onBlur()}
                required={item.required}
                error={error}
            />;
        case 'select':
            if (item.name === 'invoiceCountry') {
                options.push({value: 'CZECH REPUBLIC', label: 'Česká republika', selected: true});
                options.push({value: 'SLOVAKIA', label: 'Slovenská republika'});
            }

            if (item.name === 'contactJobTitle') {
                options = optionEnums.position;
            }

            return <UiSelect
                name={item.name}
                label={item.label}
                value={fields[item.name] || ''}
                onChange={(value) => {
                    onChange({name: item.name, value: value});
                }}
                onBlur={() => onBlur()}
                options={options}
                required={item.required}
                error={error}
            />;
        default:
            return <></>;
    }
};

// fixme: find a better place for it
const setPhonePrefixByCountry = (country: string, currentValue: string) => {
    let prefix = '';

    if (country === 'CZECH REPUBLIC') {
        prefix = '+420';
    }

    if (country === 'SLOVAKIA') {
        prefix = '+421';
    }

    return ['+420', '+421', ''].includes(currentValue) ? prefix : currentValue;
};

// todo: move to new component -> FormElement
const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
};
