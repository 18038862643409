import utils from 'utils';

import {createContext, useState} from 'react';

import {useCurrentUser, useCart, useCatalog, useProductsSearch} from '@/hooks/export';

export const AppContext = createContext<appContext>({});

export const AppContextProvider = (props: any) => {
    // todo: filters -> searchFilters, maybe it will have own context
    const [filters, setFilters] = useState(utils.parseUrlToFilters());

    const [machine, setMachine] = useState({
        brand: '',
        model: '',
        machineType: '',
        purpose: '',
    });

    const userContext = {
        ...useCurrentUser(),
    };

    const cartContext = {
        ...useCart({
            enabled: !!userContext.currentUser.id,
        }),
    };

    const catalogContext = {
        ...useCatalog({
            enabled: !!userContext.currentUser.id,
        }),
    };

    const productsContext = {
        filters,
        setFilters,
        machine,
        setMachine,
        ...useProductsSearch({filters}),
    };

    const contextValue = {
        productsContext,
        userContext,
        cartContext,
        catalogContext,
    };

    return (
        <AppContext.Provider value={contextValue}>
            {props.children}
        </AppContext.Provider>
    );
};

export default AppContext;
