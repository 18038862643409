import utils from 'utils';

import {useState, useEffect, useRef, useContext} from 'react';

import {useLabel, useMachineFilter} from '@/hooks/export';
import {MachinePartSelect, UiWeekPicker} from '@components/export';

import {TextField, Autocomplete} from '@mui/material';
import appContext from "@/appContext.tsx";

export const CartListItemOptions = (props: {
    productId: string,
    handle: any,
    options: additionalInfo,
    setOptions: any, // fixme
    hideMachineRelated?: boolean,
    hideDate?: boolean,
    productOptionsImages: machineRequestAttachment[],
}) => {
    // fixme: if user will not find brand-model in provided options

    const label = useLabel();

    const {options, setOptions} = props;

    const [machine, setMachine] = useState({
        brand: options.brand || '',
        model: options.model || '',
    });

    const imgInputRef = useRef(null);
    const [upload, setUpload] = useState<machineRequestAttachment>({
        file: null,
        purpose: '',
    });

    const {brands, models} = useMachineFilter(machine.brand);

    const {setProductOptionsImages, productOptionsImages} = useContext(appContext).cartContext;

    const data = {
        options: {
            brand: [],
            model: [],
        },
    };

    if (brands !== undefined) {
        brands
            .sort((a, b) => a > b ? 1 : -1)
            .map((brand) => {
                // data.options.brand.push({value: brand, label: brand});
                data.options.brand.push(brand);
            });
    }

    if (models.length) {
        models
            .sort((a, b) => a > b ? 1 : -1)
            .map((model) => {
                // data.options.model.push({value: model, label: model});
                data.options.model.push(model);
            });
    }

    useEffect(() => {
        setOptions({
            ...options,
            ...machine,
        })
    }, [machine]);

    useEffect(() => {
        if (upload.file && upload.purpose) {
            props.handle.addProductOptionsImage(
                props.productId,
                upload,
            );

            setUpload({
                file: null,
                purpose: '',
            });
        }
    }, [upload]);

    console.log('CartListItemOptions:87', data.options); // todo: remove!

    // fixme: too big
    return <>
        <div className={cmpClass}>
            <div className={classMap.actions}></div>
            <div className={classMap.info}>
                <div className={classMap.rows}>
                {
                    !props.hideMachineRelated ?
                        <>
                            <div className={classMap.row}>
                                <Autocomplete
                                    id={'brand'}
                                    freeSolo
                                    fullWidth
                                    options={data.options.brand}
                                    value={machine.brand}
                                    onChange={(_e, value) => setMachine({
                                        brand: value,
                                        model: '',
                                    })}
                                    renderInput={(params) => {
                                        return <TextField
                                            {...params}
                                            label={label.brand}
                                            size={'small'}
                                            fullWidth
                                        />
                                    }}
                                />
                                <Autocomplete
                                    id={'model'}
                                    freeSolo
                                    fullWidth
                                    options={data.options.model}
                                    value={machine.model}
                                    onChange={(_e, value) => setMachine({
                                        ...machine,
                                        model: value,
                                    })}
                                    renderInput={(params) => {
                                        return <TextField
                                            {...params}
                                            label={label.model}
                                            size={'small'}
                                            fullWidth
                                        />
                                    }}
                                />
                            </div>
                            <div className={classMap.row}>
                                <TextField
                                    label={label.manufacturerNum}
                                    size={'small'}
                                    fullWidth
                                    value={options.manufacturerNum}
                                    onChange={(e) => setOptions({
                                        ...options,
                                        manufacturerNum: e.target.value,
                                    })}
                                />
                                <TextField
                                    label={label.internalNum}
                                    size={'small'}
                                    fullWidth
                                    value={options.internalNum}
                                    onChange={(e) => setOptions({
                                        ...options,
                                        internalNum: e.target.value,
                                    })}
                                />
                            </div>
                            <div className={classMap.row}>
                                <TextField
                                    label={label.note}
                                    fullWidth
                                    multiline
                                    value={options.note}
                                    onChange={(e) => setOptions({
                                        ...options,
                                        note: e.target.value,
                                    })}
                                />
                            </div>
                            <div className={classMap.row}>
                                {
                                    props.productOptionsImages ?
                                        <div className={classMap.attachments}>
                                            {
                                                props.productOptionsImages?.map((attachment, attachmentIndex) => {
                                                    return <div className={classMap.attachment}>
                                                        <img
                                                            src={URL.createObjectURL(attachment.file)}
                                                            alt={attachment.file.name}
                                                        />
                                                        <i
                                                            className={'iconfa-close'}
                                                            onClick={() => {
                                                                const images = props.productOptionsImages.filter(
                                                                    (_item, index) => index !== attachmentIndex
                                                                );

                                                                setProductOptionsImages({
                                                                    ...productOptionsImages,
                                                                    [props.productId]: images,
                                                                });
                                                            }}
                                                        ></i>
                                                    </div>
                                                })
                                            }
                                        </div> :
                                        <></>
                                }
                            </div>
                            {
                                !props.hideDate ?
                                    <UiWeekPicker
                                        label={label.installDate}
                                        onChange={(value) => setOptions({
                                            ...options,
                                            execDate: value,
                                        })}
                                        value={options.execDate}
                                    /> :
                                    <></>
                            }
                        </> :
                        <>
                            <TextField
                                label={label.note}
                                fullWidth
                                multiline
                                value={options.note}
                                onChange={(e) => setOptions({
                                    ...options,
                                    note: e.target.value,
                                })}
                            />
                        </>
                }
                </div>
                {
                    !props.hideMachineRelated ?
                        <>
                            <MachinePartSelect
                                onPartSelect={(partName) => {
                                    setUpload({
                                        file: null,
                                        purpose: partName,
                                    });

                                    imgInputRef.current.click();
                                }}
                            />
                            <input
                                type="file"
                                hidden
                                ref={imgInputRef}
                                accept={'image/png, image/jpeg'}
                                onChange={(e) => {
                                    setUpload({
                                        ...upload, file: e.target.files[0],
                                    });
                                }}
                            />
                        </>
                         :
                        <></>
                }

            </div>
        </div>
    </>;
};

const cmpClass = 'cart-list-item-options';

const classMap = utils.generateClassMap(cmpClass, {
    actions: 'actions',
    action: 'action',
    attachments: 'attachments',
    attachment: 'attachment',
    row: 'row',
    rows: 'rows',
    datePicker: 'date-picker',
    info: 'info',
});
