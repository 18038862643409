import utils from 'utils';
import {useApiQuery} from '@/api';

import {useEshopConfig, useLabel} from '@/hooks/export';

export const ProductAttrs = (props: {
    id: string
}) => {
    const label = useLabel();

    const images = useEshopConfig().categoryImgMap;
    const attrsOrder = useEshopConfig().attrsOrder;

    const product = useApiQuery('getProduct', {
        id: props.id,
    }).data;

    const handle = {
        renderAttributes(attributes) {
            const newAtrributes = {};

            attributes
                .sort((a, b) => {
                    return a.value > b.value ? 1 : -1;
                })
                .sort((a, b) => {
                    return attrsOrder.indexOf(a.name) > attrsOrder.indexOf(b.name) ? 1 : -1;
                })
                .map((item: apiFilterOption) => {
                    newAtrributes[item.name] = {
                        name: item.name,
                        label: item.label,
                        value: newAtrributes[item.name] && newAtrributes[item.name].value ?
                            item.value + ' | ' + newAtrributes[item.name].value :
                            item.value,
                    };
                });

            return Object.values(newAtrributes);
        },
    };

    return <div className={cmpClass}>
        {
            checkProduct(product) && product.attributes.length ? <>
                <div className={classMap.img}>
                    <img src={images[product.category] || ''} alt=""/>
                </div>
                <div className={classMap.box}>
                    <p className={classMap.title}>
                        {label.parameters}
                    </p>
                    <div className={classMap.table}>
                        {
                            handle.renderAttributes(product.attributes)
                                .map((item: apiFilterOption, i) => {
                                    return <div className={classMap.row} key={i}>
                                        <span className={classMap.cell}>
                                            {item.label}
                                        </span>
                                        <span className={classMap.cell}>
                                            {item.value}
                                        </span>
                                    </div>;
                                })
                        }
                    </div>
                </div>
            </> : <></>
        }
    </div>;
};

/**
 * is product var valid
 */
const checkProduct = (product: apiProduct | void): product is apiProduct => {
    return ![undefined, null].includes(product as apiProduct);
};

const cmpClass = 'product-attrs';
const classMap = utils.generateClassMap(cmpClass, {
    img: 'img',
    box: 'box',
    title: 'title',
    table: 'table',
    row: 'row',
    cell: 'cell',
});
