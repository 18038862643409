import React, {useEffect} from 'react';
import Popover, {PopoverOrigin} from '@mui/material/Popover';

export const UiPopover = (props: {
    children: React.ReactNode,
    anchorEl: HTMLElement | null,
    setAnchorEl: (element: HTMLElement | null) => void,
    anchorOrigin: PopoverOrigin,
    transformOrigin: PopoverOrigin,
}) => {
    const open = Boolean(props.anchorEl);

    const handleClose = () => {
        props.setAnchorEl(null);
    };

    useEffect(() => {
        const handleScroll = () => {
            handleClose();
        };

        if (open) {
            window.addEventListener('scroll', handleScroll);
        }

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [open]);

    return (
        <Popover
            open={open}
            anchorEl={props.anchorEl}
            onClose={handleClose}
            anchorOrigin={props.anchorOrigin}
            transformOrigin={props.transformOrigin}
        >
            {props.children}
        </Popover>
    );
};
