import utils from 'utils';

import {Checkbox, FormControlLabel} from '@mui/material';

export const UiCheckbox = (props: {
    onChange: (value: boolean) => void,
    disabled?: boolean,
    checked?: boolean,
    defaultChecked?: boolean,
    label?: React.ReactNode,
}) => {
    return <FormControlLabel className={cmpClass}
        control={
            <Checkbox
                className={classMap.input}
                onChange={(e) => props.onChange(e.target.checked)}
                disabled={props.disabled}
            />
        }
        label={props.label}
        checked={props.checked}
        defaultChecked={props.defaultChecked}
    />;
};

const cmpClass = 'ui-checkbox';
const classMap = utils.generateClassMap(cmpClass, {
    input: 'input',
});
