import utils from 'utils';

import {useState, useEffect, useContext} from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';

import {FilterList} from '@components/export';
import {useLabel} from '@/hooks/export';

import appContext from '@/appContext.tsx';

export const CatalogFilters = (props: {
    purpose: string,
}) => {
    const label = useLabel();

    const isDesktop = useMediaQuery('(min-width: 1024px)');
    const [show, setShow] = useState(!!props.purpose);

    const {filters, setFilters} = useContext(appContext).productsContext;

    const handle = {
        updateFilters(data) {
            data.attributes = data.attributes.filter((item, index, self) =>
                // only unique pairs
                index === self.findIndex((t) => (
                    t.name === item.name && t.value === item.value
                )),
            );

            setFilters(data);
        },
    };

    useEffect(() => {
        setShow(isDesktop);
    }, [isDesktop]);

    return <div className={cmpClass}>
        <div className={classMap.top}>
            <p className={'title'}>
                {label.filtering}
            </p>
            <span
                onClick={() => setShow(!show)}
                className={classMap.toggleFilters}
            >
                {
                    !show ?
                        <><i className={'iconfa-arrowDown'}></i>{label.activeFilters}</> :
                        <><i className={'iconfa-arrowUp'}></i>{label.hideFilters}</>
                }
            </span>
        </div>

        {
            show ?
                <FilterList
                    purpose={props.purpose}
                    filters={filters}
                    setFilters={handle.updateFilters}
                /> :
                <></>
        }
    </div>;
};

const cmpClass = 'catalog-filters';
const classMap = utils.generateClassMap(cmpClass, {
    top: 'top',
    toggleFilters: 'toggle-filters',
    clearFilters: 'clear-filters',
});
