/**
 * Fetches data from the rest API
 * @param {string} path
 * @param {boolean} returnOnlyData
 * @returns Promise
 */
export default async function fetch(path: string, returnOnlyData: boolean = true) {
    const apiUrl = this.config?.api;

    const currentPath = window.location.pathname.split('/')[1];
    const publicPath = ['login', 'request', 'reset-password', 'wiki'];

    const headers = {
        'Content-Type': 'application/json',
    };

    const response = await this.rest.get(apiUrl + path, headers);

    if (response.code === 401) {
        if (!publicPath.includes(currentPath)) {
            document.location.replace('/login');
        }
    }

    // if 404 return null
    if (response.code === 404) {
        console.error('404: ' + response.message);
        return null;
    }

    // if status is not true, throw error
    // call .error() to show error message
    if (!response.status) {
        // console.error(response.message);
    }

    if (!returnOnlyData) {
        return response;
    }

    return response?.data;
}
