export default function convertFiltersToUrl(
    filters: any, // fixme: filters type
): string {
    const {purpose, name, attributes} = filters;

    let path = `/catalog`;

    if (purpose) {
        path += `/p-/${encodeURIComponent(purpose)}`;
    }

    if (name) {
        path += `/name-/${encodeURIComponent(name)}`;
    }

    path += `/a`;

    const params = new URLSearchParams();
    if (attributes) {
        attributes.forEach((attr: { name: string, value: string }) => {
            params.append(
                encodeURIComponent(attr.name),
                encodeURIComponent(attr.value),
            );
        });
    }

    return `${path}?${params.toString()}`;
}
