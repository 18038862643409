import utils from 'utils';
import {useContext, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {useApiQuery} from '@/api';
import {BigTitle, UiButton, OrdersList, ProductCard} from '@components/export';
import {useLabel} from '@/hooks/export';
import {Skeleton} from '@mui/material';

import appContext from '@/appContext.tsx';

export const UserProfile = () => {
    const {currentUser} = useContext(appContext).userContext;
    const navigate = useNavigate();
    const label = useLabel();

    let lastSeenProductsLs = localStorage.getItem('lastSeenProducts');
    lastSeenProductsLs = lastSeenProductsLs ? JSON.parse(lastSeenProductsLs) : [];

    const [lspls, setLspls] = useState({
        ids: [''],
    });

    const lastSeenProducts = useApiQuery('getProducts', lspls).data;

    useEffect(() => {
        if (currentUser.id) {
            setLspls({ids: lastSeenProductsLs[currentUser.id]});
        }
    }, [currentUser.id]);

    return <div className={cmpClass}>
        {
            currentUser && currentUser.id ?
                <>
                    <div className={classMap.bigTitleContent}>
                        <div className={classMap.userTitle}>
                            <span className={classMap.userIcon}></span>
                            <span>{currentUser.firstName + ' ' + currentUser.lastName}</span>
                            <span className={classMap.userCompany}>{currentUser.invoiceCompany}</span>
                        </div>
                        <BigTitle title={label.userProfileHeadline} dark={true} className={classMap.bigTitle}/>
                    </div>
                    <div className={classMap.box}>
                        <UiButton
                            style={2}
                            text={label.orders}
                            onClick={() => navigate('/orders')}
                            icon='iconfa-documentCheckMark'
                        />
                        {/* <UiButton style={2} text={label.buyAgain} onClick={() => {}} icon='iconfa-repeatArrow'/> */}
                        <UiButton
                            style={2}
                            text={label.invoices}
                            onClick={() => navigate('/invoices')}
                            icon='iconfa-invoice'
                        />
                        <UiButton
                            style={2}
                            text={label.serviceOrdersBtn}
                            onClick={() => navigate('/service-orders')}
                            icon='iconfa-service'
                        />
                        <UiButton
                            style={2}
                            text={label.userSettingsShort}
                            onClick={() => navigate('/user-settings')}
                            icon='iconfa-user'
                        />
                        <UiButton
                            style={2}
                            text={label.changePassword}
                            onClick={() => navigate('/change-password')}
                            icon='iconfa-lock'
                        />
                    </div>
                    <BigTitle title={label.orders} dark={true} className={classMap.bigTitle}/>
                    <div className={classMap.horizontal}>
                        <OrdersList limit={5} offset={0}/>
                        <div className={classMap.showMore}>
                            <UiButton
                                style={2}
                                text={label.showMore}
                                onClick={() => navigate('/orders')}
                            />
                        </div>
                    </div>
                    <BigTitle title={label.lastSeen} dark={true} className={classMap.bigTitle}/>
                    <div className={classMap.horizontal}>
                        <div className={classMap.list}>
                            {
                                lastSeenProducts === undefined ?
                                    [...Array(4)].map((_v, i) => <Skeleton className={classMap.listItem} key={i}/>) :
                                    checkProducts(lastSeenProducts) && lastSeenProducts.length !== 0 ?
                                        lastSeenProducts.map(
                                            (product, key) => <div className={classMap.listItem} key={key}>
                                                <ProductCard product={product} />
                                            </div>,
                                        ) :
                                        <>{label.noProducts}</>
                            }
                        </div>
                    </div>
                </> :
                <></>
        }
    </div>;
};

/**
 * is products var valid
 * @param {array} products
 * @returns {boolean}
 */
const checkProducts = (products: apiProduct[] | void): products is apiProduct[] => {
    return ![undefined, null].includes(products as apiProduct[]);
};

const cmpClass = 'user-profile';

const classMap = utils.generateClassMap(cmpClass, {
    bigTitle: 'big-title',
    box: 'box',
    horizontal: 'horizontal',
    list: 'list',
    listItem: 'list-item',
    listTitle: 'list-title',
    bigTitleContent: 'big-title-content',
    userTitle: 'user-title',
    userIcon: 'icon iconfa-user',
    showMore: 'show-more',
    userCompany: 'user-company',
});
