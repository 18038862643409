import utils from 'utils';

import categoryImg1 from "@img/cat_1_VZV.png";

export const MachinePartSelect = (props: {
    onPartSelect: (partName: string) => void,
}) => {
    const purposeOptions = [
        {value: 'frontWheel', label: 'Přední kolo'},
        {value: 'backWheel', label: 'Zadní kolo'},
        {value: 'label', label: 'Štítek'},
        {value: 'whole', label: 'Celek'},
    ]

    return <div className={cmpClass}>
        <div className={classMap.wrapper}>
            <img src={categoryImg1}/>
            <div className={classMap.links}>
                {
                    purposeOptions
                        .filter((purpose) => !!purpose.value)
                        .map((purpose, i) => {
                            const linkClass = 'link' + purpose.value.charAt(0).toUpperCase() + purpose.value.slice(1);

                            return <div
                                className={classMap.link + ' ' + classMap[linkClass]}
                                key={i}
                            >
                                <i
                                    className={'iconfas-upload'}
                                    onClick={() => props.onPartSelect(purpose.value)}
                                ></i>
                                <span>{purpose.label}</span>
                            </div>;
                        })
                }
            </div>
        </div>
    </div>;
}

const cmpClass = 'machine-part-select';
const classMap = utils.generateClassMap(cmpClass, {
    wrapper: 'wrapper',
    link: 'link',
    links: 'links',

    // fixme: dehardcode
    linkFrontWheel: 'link--front-wheel',
    linkBackWheel: 'link--back-wheel',
    linkLabel: 'link--label',
    linkWhole: 'link--whole',
});
