
/**
 * 
 * @param string eventType 
 * @returns 
 */
export default async function logEvent(eventType: string, data: any): Promise<boolean> {
    const resp = await this.post('system/logEvent', {
        data: {
            type: eventType,
            data,
        },
    });

    if (!resp.status) {
        console.error('logEvent', resp.message);
        return false;
    }

    return true;
}