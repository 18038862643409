export default function parseUrlToFilters(url?: string): any {
    if (!url) {
        url = window.location.href.replace(window.location.protocol + '//' + window.location.host, '');
    }

    const filters: any = {
        attributes: [],
    };

    const urlParts = url.split('?');
    const pathParts = urlParts[0].split('/');

    pathParts.forEach((part, index) => {
        if (part.startsWith('p-')) {
            filters.purpose = decodeURIComponent(pathParts[index + 1]);
        }
        if (part.startsWith('name-')) {
            filters.name = decodeURIComponent(pathParts[index + 1]);
        }
    });

    if (urlParts[1]) {
        const queryParams = new URLSearchParams(urlParts[1]);
        queryParams.forEach((value, key) => {
            filters.attributes.push({
                name: decodeURIComponent(key),
                value: decodeURIComponent(value),
            });
        });
    }

    return filters;
}
