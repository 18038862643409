import utils from 'utils';

import toast from 'react-hot-toast';
import {useContext, useRef, useState} from 'react';
import {useQueryClient} from '@tanstack/react-query';
import {TextField} from '@mui/material';

import {useLabel} from '@/hooks/export';
import appContext from '@/appContext';

export const CartNote = (props: {
    initValue: string,
}) => {
    const queryClient = useQueryClient();
    const label = useLabel();
    let timeoutRef = useRef(null);

    const cartContext = useContext(appContext).cartContext;

    const [note, setNote] = useState(props.initValue);

    const handle = {
        updateNote(note: string = '') {
            setNote(note);

            if (!timeoutRef.current) {
                toast.remove();
                toast.loading(label.loading);
            }

            utils.doWithTimeout(() => {
                cartContext.updateNote(note)
                    .then(() => {
                        queryClient.invalidateQueries({queryKey: ['cart']})
                            .then(() => {
                                toast.remove();
                                toast.success(label.cartIsUpToDate);
                            });
                    });
            }, 1000, timeoutRef);
        },
    };

    return (
        <div className={cmpClass}>
            <div className={classMap.itemBox}>
                <p className={classMap.title}>
                    {label.cartNote}
                </p>

                <TextField
                    label={label.note}
                    fullWidth
                    multiline
                    value={note}
                    className={classMap.note}
                    onChange={(e) => {
                        handle.updateNote(e.target.value);
                    }}
                />
            </div>
        </div>
    );
};

const cmpClass = 'cart-note';

const classMap = utils.generateClassMap(cmpClass, {
    itemBox: 'item-box',
    title: 'title',
    note: 'note',
});
