import utils from 'utils';

import {
    FormControl,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
} from '@mui/material';

export const UiSelect = (props: {
    name: string,
    label: string,
    value: string|number,
    onChange: (value: string|number) => void,
    onBlur?: () => void,
    options: {value: string; label: string|JSX.Element}[],
    itemAction?: {iconClass: string, onClick: (value: string|number) => void},
    required?: boolean,
    error?: boolean,
    style?: number,
}) => {
    return <FormControl
        fullWidth
        size="small"
        className={cmpClass + ' ' + cmpClass + (props.style ? '--style-' + props.style : '--style-1')}
        key={props.name}
    >
        <InputLabel id={props.name}>
            {props.label + (props.required ? ' *' : '')}
        </InputLabel>
        <Select
            className={classMap.select}
            id={props.name}
            value={props.value}
            label={props.label}
            labelId={props.name}
            onChange={(e) => {
                props.onChange(e.target.value);
            }}
            onBlur={() => {
                return props.onBlur !== undefined ? props.onBlur() : undefined;
            }}
            MenuProps={{
                disableScrollLock: true,
            }}
            error={props.error}
            required={props.required}
        >
            {
                props.options
                    .map((item, index) => {
                        return <MenuItem
                            value={item.value}
                            key={index}
                            className={
                                classMap.option + ' ' +
                                classMap.option + (props.style ? '--style-' + props.style : '--style-1') + ' ' +
                                (props.itemAction ? ' ' + classMap.optionWithAction : '')
                            }
                            onClick={() => {
                                props.onChange(item.value);
                            }}
                        >
                            {
                                props.itemAction && item.value !== '' ?
                                    <>
                                        <span>{item.label}</span>
                                        <IconButton
                                            onClick={(e) => {
                                                props.itemAction.onClick(item.value);
                                                e.stopPropagation();
                                            }}
                                            size={'small'}
                                        >
                                            <i className={props.itemAction.iconClass}/>
                                        </IconButton>
                                    </> :
                                    item.label
                            }
                        </MenuItem>;
                    })
            }
        </Select>
    </FormControl>;
};

const cmpClass = 'ui-select';
const classMap = utils.generateClassMap(cmpClass, {
    select: 'select',
    option: 'option',
    optionWithAction: 'option--with-action',
});
