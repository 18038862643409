/**
 * Posts data to the rest API
 * @param {string} path
 * @param {any} data
 * @param {any} customHeaders
 * @returns Promise
 */
export default async function post(path: string, data: any, customHeaders: any = {}) {
    const apiUrl = this.config?.api;

    const currentPath = window.location.pathname.split('/')[1];
    const publicPath = ['login', 'request', 'reset-password', 'wiki'];

    const headers = {
        'Content-Type': 'application/json',
        ...customHeaders,
    };

    const response = await this.rest.post(apiUrl + path, data, headers);

    if (response.code === 401) {
        if (!publicPath.includes(currentPath)) {
            document.location.replace('/login');
        }
    }

    // if status is not true, throw error
    // call .error() to show error message
    if (!response.status) {
        // console.error(response.message);
    }

    return response;
}
