import utils from 'utils';

import {useState, useEffect, useRef} from 'react';
import toast from 'react-hot-toast';

import {CartListItemOptions, CountInput, ProductStockInfo} from '@components/export';
import {useCart, useLabel} from '@/hooks/export';

export const CartListItem = (props: {
    handle: any,
    product: any,
    productOptionsImages: machineRequestAttachment[],
    readonly?: boolean,
}) => {
    // todo? split additionalInfo to groups - machine (machine-related), exec (montaž-related), common (note)
    // todo: place count state here, pass setState as onChange

    // todo: isolate repeats on row level

    const label = useLabel();
    const product = props.product;
    const timeoutRef = useRef(null);

    const data = {
        cartFlags: useCart().flags,
        cart: useCart().cart,
        selectedDelivery: {id: '', optionId: ''},
    };

    if (data.cart !== undefined) {
        data.selectedDelivery = (data.cart as unknown as apiCart).selectedDelivery;
    }

    // fixme:
    const [additionalInfo, setAdditionalInfo] = useState(
        product.additionalInfo?.manufacturerNum !== undefined ?
            product.additionalInfo :
            {
                manufacturerNum: '',
                internalNum: '',
                note: '',
                execDate: '',
                execWeek: '',
            },
    );

    const noPriceLabel = '--,-';

    const priceByQty = product.price.current * product.qty;

    const formattedPrice = {
        current: product.price.current ? utils.formatPrice(product.price.current) : noPriceLabel,
        prev: product.price.prev ? utils.formatPrice(product.price.prev) : noPriceLabel,
        byQty: priceByQty ? utils.formatPrice(priceByQty) : noPriceLabel,
    };

    const flags = {
        needsExec:
            !!product?.additionalServices && Object.entries(product.additionalServices)
                .filter((entry: any) => entry[1].qty > 0)
                .length,
        allowOptions: product.category !== '0',
        allowMachineRelatedOptions: product.category === '2',
        isAdditionalInfoFilled: Object.values(additionalInfo).some((value) => !!value),
    };

    const [showOptions, setShowOptions] = useState(flags.isAdditionalInfoFilled);
    const [qty, setQty] = useState(product.qty || 1);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        props.handle.updateItem(
            product.id,
            product.qty,
            product.additionalServices,
            additionalInfo,
        );
    }, [additionalInfo]);

    useEffect(() => {
        // fixme: temp fix - set all service qty same as product
        // fixme! exclude delivery and montaž

        setLoading(true);

        if (!timeoutRef.current) {
            toast.remove();
            toast.loading(label.loading);
        }

        utils.doWithTimeout(() => {
            const tempServices: any = {};

            for (const prop in product.additionalServices) {
                tempServices[prop] = {
                    ...product.additionalServices[prop],
                    qty: product.additionalServices[prop].qty ? qty : 0,
                };
            }

            props.handle.updateItem(
                product.id,
                qty,
                tempServices,
            )
                .then(() => {
                    setLoading(false);
                });
        }, 1000, timeoutRef);
    }, [qty]);

    return <div className={cmpClass}>
        <div className={classMap.row}>
            <div className={classMap.box}>
                <div className={classMap.img}><img src={product.img} alt=""/></div>
                <div className={classMap.product}>
                    <div className={classMap.title} onClick={() => props.handle.navigateToProduct(product.id)}>{product.title}</div>
                    <div className={classMap.info}>
                        <span className={classMap.code}>
                            {label.productCodeShort + ': ' + product.code}
                        </span>
                        <ProductStockInfo
                            stockInfo={product.info.stockInfo}
                            stock={product.info.stock}
                            showAsPopover
                        />
                        {
                            product.message.length ?
                                product.message.map((message: string, index: number) => {
                                    return <p key={index} className={classMap.message}>{message}</p>;
                                }) :
                                <></>
                        }
                    </div>
                </div>
            </div>
            <div className={classMap.box + (props.readonly ? ' readonly' : '')}>
                <div className={classMap.price}>
                    <span className={classMap.priceCurrent}>
                        <span>
                            {
                                (product.qty <= 1 ? formattedPrice.current : formattedPrice.byQty) + ' ' + label.currency
                            }
                        </span>
                        {
                            product.qty > 1 ?
                                <span className={'secondary'}>
                                    {'(' + formattedPrice.current + ' ' + label.currency + ' x ' + product.qty + label.qty + ')'}
                                </span> :
                                <></>
                        }
                    </span>
                </div>
                <div className={classMap.action}>
                    {
                        !product.hideQty ?
                            <CountInput
                                value={qty}
                                onChange={setQty}
                                readonly={props.readonly}
                                disabled={loading}
                                max={
                                    data.cartFlags.isSelfPickup ?
                                        product.info.stockInfo.locations[data.selectedDelivery.optionId]?.qty || 0 :
                                        99
                                }
                            /> : <></>
                    }
                    <div className={classMap.actionBtns}>
                        {
                            !props.readonly ?
                                <button onClick={() => props.handle.dropItem(product.id)}>
                                    <i className={classMap.iconTrash}></i>
                                    <span> {label.drop}</span>
                                </button> :
                                <></>
                        }
                        {
                            flags.allowOptions ?
                                <button onClick={() => setShowOptions(!showOptions)}>
                                    <>
                                        <i className={showOptions ? 'iconfas-edit' : 'iconfa-edit'}></i>
                                        <span> {label.specify}</span>
                                    </>
                                </button> :
                                <></>
                        }
                    </div>
                </div>
            </div>
        </div>
        <div className={classMap.row}>
            {
                showOptions ?
                    <CartListItemOptions
                        productId={product.id}
                        handle={props.handle}
                        options={additionalInfo}
                        setOptions={setAdditionalInfo}
                        hideDate={!flags.needsExec}
                        hideMachineRelated={!flags.allowMachineRelatedOptions}
                        productOptionsImages={props.productOptionsImages}
                    /> :
                    <></>
            }
        </div>
    </div>;
};

const cmpClass = 'cart-list-item';
const classMap = utils.generateClassMap(cmpClass, {
    action: 'action',
    actionBtns: 'action-btns',
    box: 'box',
    code: 'code',
    img: 'img',
    title: 'title',
    info: 'info',
    iconTrash: 'icon-trash iconfa-trash',
    message: 'message',
    price: 'price',
    product: 'product',
    priceCurrent: 'price-current',
    pricePrev: 'price-prev',
    row: 'row',
});
