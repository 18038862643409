import {api} from '@/api';
import utils from 'utils';

import {useContext} from 'react';
import {useQueryClient} from '@tanstack/react-query';

import {UiCheckbox} from '@components/export.ts';
import {useLabel} from '@/hooks/export';

import appContext from '@/appContext';

export const CartDelivery = (props: {
    readonly?: boolean,
}) => {
    const label = useLabel();
    const queryClient = useQueryClient();

    const {cart} = useContext(appContext).cartContext;

    const handle = {
        selectDelivery(id: string, optionId: string, selected: boolean) {
            // todo: 1st option must be selected by default

            api.cart.updateDelivery({id, optionId, selected})
                .then(() => {
                    queryClient.invalidateQueries({
                        queryKey: ['cart'],
                    });
                });
        },
    };

    // fixme: split to CartDeliveryItem + CartDeliveryItemOption
    return <div className={cmpClass}>
        {
            cart && cart.delivery ?
                Object.entries(cart.delivery).map(([deliveryItemId, deliveryItem]: [string, deliveryItem]) => {
                    return (!props.readonly || deliveryItem.selected) ?
                        <div className={classMap.item} key={deliveryItemId}>
                            <div className={classMap.box}>
                                <div className={classMap.info}>
                                    <div className={classMap.title}>{deliveryItem.name}</div>
                                    {
                                        deliveryItem.message ?
                                            <div className={classMap.message}>{deliveryItem.message}</div> :
                                            <></>
                                    }
                                </div>
                                <div className={classMap.state}>
                                    <div className={classMap.price}>
                                        {utils.formatPrice(deliveryItem.price) + ' ' + label.currency}
                                    </div>
                                    {
                                        !props.readonly ?
                                            <div className={classMap.selected}>
                                                <UiCheckbox
                                                    onChange={(selected) => handle.selectDelivery(deliveryItemId, '', selected)}
                                                    checked={deliveryItem.selected}
                                                />
                                            </div> :
                                            <></>
                                    }
                                </div>
                            </div>
                            {
                                Object.entries(deliveryItem.options).length ?
                                    <div className={classMap.options}>
                                        {
                                            Object.entries(deliveryItem.options).map(([deliveryOptionId, deliveryOption]) => {
                                                return <div className={classMap.option} key={deliveryOptionId}>
                                                    <UiCheckbox
                                                        onChange={(selected) => handle.selectDelivery(deliveryItemId, deliveryOptionId, selected)}
                                                        checked={deliveryOption.selected}
                                                        disabled={deliveryOption.disabled}
                                                        label={
                                                            <div className={classMap.optionLabel}>
                                                                <p>
                                                                    <span>{deliveryOption.name}</span>
                                                                    <span> (</span>
                                                                    <span>
                                                                        <a
                                                                            href={deliveryOption.locationLink}
                                                                            target="_blank"
                                                                            rel="noreferrer"
                                                                            className={classMap.locationLink}
                                                                        >
                                                                            {deliveryOption.locationAddress}
                                                                        </a>
                                                                    </span>
                                                                    <span>)</span>
                                                                </p>
                                                                <p>
                                                                    <span className={classMap.message}>{deliveryOption.message}</span>
                                                                </p>
                                                            </div>
                                                        }
                                                    />
                                                </div>;
                                            })
                                        }
                                    </div> :
                                    <></>
                            }
                        </div> : <></>;
                }) :
                <></>
        }
    </div>;
};

const cmpClass = 'cart-delivery';
const classMap = utils.generateClassMap(cmpClass, {
    item: 'item',
    box: 'box',
    info: 'info',
    title: 'title',
    options: 'options',
    option: 'option',
    optionLabel: 'option-label',
    state: 'state',
    selected: 'selected',
    price: 'price',
    message: 'message',
    locationLink: 'location-link',
});
