import {useParams} from 'react-router-dom';
import {
    Footer,
    Header,
    OrderDetail,
} from '@components/export';

export const OrderPage = () => {
    const orderId = useParams().id;

    return <>
        <Header/>

        <div className={'page-content ' + cmpClass}>
            <div className="order-page__container container">
                <OrderDetail id={orderId}/>
            </div>

        </div>

        <Footer/>
    </>;
};

const cmpClass = 'order-page';
