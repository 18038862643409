import utils from 'utils';

import React from 'react';

import {CategoryCard} from '@components/export';

import {Skeleton} from '@mui/material';

export const CategoriesGrid = (props: {
    options: {name: string, image: string, label: string}[],
    onClick: (name: string) => void
}) => {
    // todo: get category data from api
    return <>
        <div className={cmpClass}>
            <div className={classMap.wrapper}>
                {
                    props.options.map((option, index) => {
                        return <React.Fragment key={index}>
                            {
                                option.name ?
                                    <CategoryCard
                                        img={option.image}
                                        name={option.name}
                                        label={option.label}
                                        onClick={(name) => props.onClick(name)}
                                    /> :
                                    <>
                                        <Skeleton className={'category-card'} height={'100%'} key={'a'}/>
                                        <Skeleton className={'category-card'} height={'100%'} key={'b'}/>
                                        <Skeleton className={'category-card'} height={'100%'} key={'c'}/>
                                        {/* <Skeleton className={'category-card'} height={'100%'} key={'d'}/>*/}
                                    </>
                            }
                        </React.Fragment>;
                    })
                }
            </div>
        </div>
    </>;
};

const cmpClass = 'categories-grid';
const classMap = utils.generateClassMap(cmpClass, {
    wrapper: 'wrapper',
});
