import utils from 'utils';

import {useNavigate} from 'react-router-dom';
import {useEffect, useContext} from 'react';

import {
    BigTitle,
    ProductsControlBar,
    ProductsGrid,
    UiButton,
} from '@components/export';
import {useLabel} from '@/hooks/export';

import useMediaQuery from '@mui/material/useMediaQuery';

import appContext from '@/appContext';

export const ProductsDisplayManager = (props: {
    limit: number,
    isDemo: boolean,
    mode: 'catalog' | 'recommended' | 'alternative',
    alternativeTo?: string,
    purpose?: string,
    useFilters?: boolean,
    initFilters?: any,
    viewMode?: 'grid' | 'list',
}) => {
    const label = useLabel();
    const navigate = useNavigate();
    const isDesktop = useMediaQuery('(min-width: 1024px)');

    const {filters, setFilters, searchRes, searchResQuery} = useContext(appContext).productsContext;

    // todo: switch this by user in controls section
    // const [viewMode, setViewMode] = useState(props.viewMode || 'grid');

    const viewMode = props.viewMode || 'grid';

    const data = {
        productsCount: Array.isArray(searchRes?.pages) && searchRes.pages.at(-1).products.length,
    };

    const flags = {
        noProducts: !searchRes?.pages[0].products.length,
        showMore: data.productsCount >= props.limit,
        isScrollable:
            props.mode === 'alternative' &&
            (isDesktop ? data.productsCount > 4 : data.productsCount > 2),
    };

    const handle = {
        clickLoadMore: () => {
            return props.isDemo ?
                navigate('/catalog') :
                setFilters({
                    ...filters,
                    offset: filters.offset + filters.limit,
                });
        },
        cleanFilters() {
            setFilters({
                ...filters,
                attributes: [],
                name: '',
                purpose: '',
            });
        },
        selectFilter: (name: string, value: string) => {
            setFilters({
                ...filters,
                offset: 0,
                attributes: filters.attributes.filter((item) => {
                    return item.name !== name || item.value !== value;
                }),
            });
        },
    };

    useEffect(() => {
        const newFilters = {
            ...filters,
            offset: 0,
            limit: props.limit,
            recommended: props.mode === 'recommended',
            alternativeTo: props.mode === 'alternative' ? props.alternativeTo : '',
            attributes: [],
            sort: 'manufacturerAsc', // fixme: read from config
            useFilters: !!props.useFilters,
            ...props.initFilters,
        };

        if (props.purpose) {
            newFilters.purpose = props.purpose;
        }

        if (props.mode === 'recommended' || props.mode === 'alternative') {
            newFilters.name = '';
            newFilters.attributes = [];
        }

        if (props.mode === 'recommended') {
            newFilters.purpose = '';
        }

        setFilters(newFilters);
    }, []);

    return <div className={cmpClass}>
        {
            props.mode === 'alternative' && !flags.noProducts ?
                <BigTitle title={label.alternativeProducts} dark={true} className={'product-page-products__title'}/> :
                <></>
        }
        {
            props.mode === 'catalog' ?
                <div className={classMap.controlBar}>
                    <ProductsControlBar handle={handle} />
                </div> :
                <></>
        }
        {
            props.mode !== 'alternative' || !flags.noProducts ?
                <>
                    {
                        viewMode === 'grid' ?
                            <ProductsGrid
                                pageSize={props.limit}
                                mode={props.mode}
                            /> :
                            <div>
                                PRODUCTS LIST
                            </div>
                    }
                    {
                        flags.noProducts || !flags.showMore || props.mode === 'alternative' ?
                            <></> :
                            <div className={classMap.loadMore}>
                                <UiButton
                                    style={1}
                                    text={label.showMore}
                                    onClick={() => handle.clickLoadMore()}
                                    loading={searchResQuery.isFetching}
                                />
                            </div>
                    }
                </> :
                <></>
        }
    </div>;
};

const cmpClass = 'products-display-manager';
const classMap = utils.generateClassMap(cmpClass, {
    controlBar: 'control-bar',
    loadMore: 'load-more',
});
