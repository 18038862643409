import utils from 'utils';

import {useState, useEffect, useRef} from 'react';

export const CountInput = (props: {
    value: number,
    onChange: (counter: number) => void,
    max?: number,
    readonly?: boolean,
    disabled?: boolean,
}) => {
    const timeoutRef = useRef(null);

    const [count, setCount] = useState(props.value);
    const [lock, setLock] = useState(false);
    const maxValue = props.max !== undefined ? props.max || 1 : 99;

    const handle = {
        onChange(value: number) {
            if (!props.readonly) {
                setCount(value);
            }
        },
        manualInput(value: number) {
            if (!props.readonly) {
                setCount(value);
                setLock(true);
            }
        },
        increment() {
            if (count < maxValue) {
                if (!props.readonly) {
                    setCount(props.value + 1);
                }
            }
        },
        decrement() {
            if (count > 1) {
                if (!props.readonly) {
                    setCount(props.value - 1);
                }
            }
        },
    };

    if (count < 1) {
        setCount(1);
        setLock(false);
    } else if (count > maxValue) {
        setCount(maxValue);
        setLock(false);
    }

    useEffect(() => {
        if (!lock) {
            props.onChange(count);
        } else {
            utils.doWithTimeout(() => {
                props.onChange(count);
                setLock(false);
            }, 1000, timeoutRef);
        }
    }, [count]);

    useEffect(() => {
        setCount(props.value);
    }, [props.value]);

    return <div className={cmpClass + (props.disabled || lock ? ' ' + classMap.loading : '')}>
        <span
            onClick={() => handle.decrement()}
            className={
                (props.readonly ? classMap.hidden : '') +
                (count <= 1 ? ' ' + classMap.disabled : '')
            }
        >
            {'-'}
        </span>
        <input
            type="text"
            inputMode="numeric"
            value={count || ''}
            onChange={(e) => handle.manualInput(+e.target.value || 0)}
            onClick={(e) => (e.target as HTMLInputElement).select()}
            disabled={props.readonly || props.disabled}
        />
        <span
            onClick={() => handle.increment()}
            className={
                (props.readonly ? classMap.hidden : '') +
                (count >= maxValue ? ' ' + classMap.disabled : '')
            }
        >
            {'+'}
        </span>
    </div>;
};

const cmpClass = 'count-input';

const classMap = utils.generateClassMap(cmpClass, {
    hidden: 'hidden',
    disabled: 'disabled',
    loading: 'loading',
});
