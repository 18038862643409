import utils from 'utils';

// todo: unify getting data and/or status

export const api = {
    user: {
        login: (creds) => {
            return utils.post('user/login', creds);
        },
        current: () => {
            return utils.fetch('user/current', false);
        },
        logout: () => {
            return utils.post('user/logout', {});
        },
        register: (fields) => {
            return utils.post('user/register', {form: fields});
        },
        forgotPass: (email) => {
            return utils.post('user/password/forgot', {email});
        },
        resetPass: (data) => {
            return utils.post('user/password/reset', data);
        },
        checkToken: (token) => {
            return utils.fetch('user/password/reset?token=' + token, false);
        },
        getAddresses: () => {
            return utils.fetch('user/address');
        },
        saveAddress: (data) => {
            return utils.post('user/address', data);
        },
        deleteAddress: (id: string) => {
            return utils.post('user/address/' + id + '/delete', {});
        },
        deleteContact: (id: string) => {
            return utils.post('user/contact/' + id + '/delete', {});
        },
        getContacts: () => {
            return utils.fetch('user/contact');
        },
        saveContact: (data) => {
            return utils.post('user/contact', data);
        },
        getRegisterFormEnums: () => {
            return utils.fetch('user/registerEnum', false)
                .then((response) => response.data);
        },
    },
    products: {
        getSingle: (id: string) => {
            return utils.fetch('products/' + id);
        },
        getMany: (data: any) => {
            return utils.post('products/search', data)
                .then((response) => response.data);
        },
        getByIds: (ids: string[]) => {
            return utils.post('products/get', {ids})
                .then((response) => response.data);
        },
    },
    filters: {
        index: (data: any) => {
            return utils.post('filters/index', data)
                .then((response) => response.data);
        },
        machines: (machine) => {
            return utils.post('filters/machines', machine)
                .then((response) => response.data);
        },
    },
    machine: {
        attributes: (fields: any) => {
            return utils.post('machine/attributes', fields)
                .then((response) => response.data);
        },
        request: (data: FormData) => {
            return utils.post(
                'machine/request',
                data,
                {'Content-Type': 'multipart/form-data'},
            )
                .then((response) => response.data);
        },
    },
    cart: {
        get: () => {
            return utils.fetch('cart', false)
                .then((response) => response.data);
        },
        update: (data) => {
            return utils.post('cart/update', data);
        },
        clean: () => {
            return utils.post('cart/clean', {});
        },
        updateNote: (data) => {
            return utils.post('cart/updateNote', data);
        },
        updateDelivery: (data) => {
            return utils.post('cart/updateDelivery', data);
        },
        submit: (data) => {
            return utils.post('order/create', data);
        },
        sendAdditionalInfo: (orderId: string, additionalInfo: any) => {
            return utils.post('order/'+ orderId +'/additionalInfo', {additionalInfo});
        },
    },
    orders: {
        get: (data) => {
            if (data?.id) {
                return utils.fetch('order/' + data.id, false)
                    .then((response) => response.data);
            }

            return utils.fetch(`order/get?limit=${data.limit}&offset=${data.offset}`, false)
                .then((response) => response.data);
        },
        count: () => {
            return utils.fetch('order/get/count', false)
                .then((response) => response.data);
        },
    },
    invoices: {
        get: (data) => {
            if (data?.id) {
                return utils.fetch('invoice/' + data.id, false)
                    .then((response) => response.data);
            }

            return utils.fetch(`invoice/get?limit=${data.limit}&offset=${data.offset}`, false)
                .then((response) => response.data);
        },
        count: () => {
            return utils.fetch('invoice/get/count', false)
                .then((response) => response.data);
        },
    },
    serviceOrders: {
        get: (data) => {
            if (data?.id) {
                return utils.fetch('service-orders/' + data.id, false)
                    .then((response) => response.data);
            }

            return utils.fetch(`service-orders/get?limit=${data.limit}&offset=${data.offset}`, false)
                .then((response) => response.data);
        },
        count: () => {
            return utils.fetch('service-orders/get/count', false)
                .then((response) => response.data);
        },
    },
    catalog: {
        getPurposeList: () => {
            return utils.fetch('catalog/purposeList', false)
                .then((response) => response.data);
        },
    },
    wiki: {
        getPage: (id) => {
            return utils.fetch('wiki/' + id, false)
                .then((response) => response.data);
        },
    },
    company: {
        checkIco: (ico) => {
            return utils.fetch('company/checkIco/' + ico, false)
                .then((response) => response.data);
        },
    },
};

export {useApiQuery} from './useApiQuery.ts';
export {useApiInfiniteQuery} from './useApiInfiniteQuery.ts';
