import utils from 'utils';

import {useContext} from 'react';
import appContext from "@/appContext.tsx";

// fixme! dehardcode
export const FooterMenu = () => {
    const {currentUser} = useContext(appContext).userContext;

    const data = {
        social: {
            facebook: 'https://www.facebook.com/cemat.cz/',
            instagram: 'https://www.instagram.com/cemat_trading/',
            linkedin: 'https://www.linkedin.com/company/2758499',
            youtube: 'https://www.youtube.com/user/cemattrading',
        },
    };

    return <div className={cmpClass}>
        <div className={classMap.container}>
            <div className={classMap.box}>
                <div className={classMap.list}>
                    <div className={classMap.title}>Informace</div>
                    <a href="/wiki/o-nas">O nás</a>
                    <a href="/wiki/montaz-a-lisovani-pneumatik">Montáž a lisování pneumatik</a>
                    <a href="/wiki/vseobecne-obchodni-podminky">Všeobecné obchodní podmínky</a>
                    <a href="/wiki/zpracovani-osobnich-udaju">Zpracování osobních údajů</a>
                    <a href="/wiki/reklamacni-rad">Reklamační řád</a>
                </div>
            </div>
            <div className={classMap.box}>
                <div className={classMap.list}>
                    <div className={classMap.title}>Kontakty</div>
                    <a href="/wiki/obchodni-oddeleni">Obchodní oddělení</a>
                    {
                        !!currentUser.id && <a href="/machine-request">Kontaktní formulář - poptávka</a>
                    }
                    <br/>
                    <a href="tel:+420596013541">tel.: +420 596 013 541</a>
                    <a href="mailto:cemat@cemat.cz">e-mail: cemat@cemat.cz</a>
                </div>
            </div>
            <div className={classMap.box + ' ' + classMap.boxNoMobile}>
                <div className={classMap.list}>
                    <div className={classMap.title}>Adresa</div>
                    <a href="https://maps.app.goo.gl/hS8TVjqzVAhSBsWh9">
                        ČEMAT trading, spol. s r.o.<br/>
                        Čs. armády 184<br/>
                        735 51 Bohumín<br/>
                    </a>
                </div>
                <div className={classMap.social}>
                    <a href={data.social.facebook} className="iconfab-facebook" target={'blank'}></a>
                    <a href={data.social.instagram} className="iconfab-instagram" target={'blank'}></a>
                    <a href={data.social.linkedin} className="iconfab-linkedin" target={'blank'}></a>
                    <a href={data.social.youtube} className="iconfab-youtube" target={'blank'}></a>
                </div>
            </div>
            <div className={classMap.box + ' ' + classMap.boxMobile}>
                <div className={classMap.social}>
                    <a href={data.social.facebook} className="iconfab-facebook" target={'blank'}></a>
                    <a href={data.social.instagram} className="iconfab-instagram" target={'blank'}></a>
                    <a href={data.social.linkedin} className="iconfab-linkedin" target={'blank'}></a>
                    <a href={data.social.youtube} className="iconfab-youtube" target={'blank'}></a>
                </div>
            </div>
        </div>
    </div>;
};

const cmpClass = 'footer-menu';
const classMap = utils.generateClassMap(cmpClass, {
    box: 'box',
    boxMobile: 'box--mobile',
    boxNoMobile: 'box--no-mobile',
    container: 'container container',
    list: 'list',
    menu: 'menu',
    social: 'social',
    title: 'title',
});
