import utils from 'utils';
import {useContext} from 'react';

import appContext from '@/appContext';

import {useNavigate} from 'react-router-dom';

export const HeaderMainMenu = (props: {
    onToggleMenu: () => void,
    active: boolean,
}) => {
    const navigate = useNavigate();
    const cartContext = useContext(appContext).cartContext;

    const data = {
        itemCount: cartContext.cart && cartContext.cart.list ? countCartProducts(cartContext.cart.list) : 0,
        hrefSearch: '/catalog',
        hrefCart: '/cart',
    };

    const flags = {
        isCurrentPageCatalog: window.location.pathname.startsWith('/catalog'),
    };

    return <div className={cmpClass}>
        {
            !flags.isCurrentPageCatalog && (
                <div className={classMap.item} onClick={() => navigate(data.hrefSearch)}>
                    <a className={classMap.iconSearch}></a>
                </div>
            )
        }
        <div className={classMap.item} onClick={() => navigate(data.hrefCart)}>
            <a className={classMap.iconCart}></a>
            {
                data.itemCount ?
                    <div className={classMap.badge}>{data.itemCount}</div> :
                    <></>
            }
        </div>
        <div className={classMap.item} onClick={() => props.onToggleMenu()}>
            <a className={props.active ? classMap.iconClose : classMap.iconMenu}></a>
        </div>
    </div>;
};

const cmpClass = 'header-main-menu';
const classMap = utils.generateClassMap(cmpClass, {
    item: 'item',
    badge: 'badge',
    iconCart: 'icon iconfa-cart',
    iconSearch: 'icon iconfa-search',
    iconMenu: 'icon iconfa-menu',
    iconClose: 'icon iconfa-close',
    hide: 'hide',
});

const countCartProducts = (list: any) => { // fixme: any -> apiCartProduct
    return Object.entries(list).reduce(
        (acc: number, item: [string, apiProduct]) => {
            // todo: dehardcode

            if ([
                '81f84f33-9af2-dd95-819c-6139e437460d',
                '58c6eca9-0e6d-fe91-dacb-6139e453d9ab',
                '9ef0e811-45e0-537c-4f79-6139e4bfb5b5',
                'ecb5e334-37a6-5fd7-ad02-63b43c9279d2',
            ].includes(item[0])) {
                return acc;
            } else {
                return acc += item[1].qty;
            }
        },
        0,
    );
};
