/**
 * Executes callback with delay
 * can be cancellable by passing timeoutRef
 *
 *
 * @param callback
 * @param delay
 * @param timeoutRef
 */
export default async function doWithTimeout(
    callback: () => any,
    delay: number,
    timeoutRef?: React.MutableRefObject<ReturnType<typeof setTimeout> | null>,
) {
    if (timeoutRef === undefined || timeoutRef === null) {
        setTimeout(() => {
            callback();
        }, delay);
    } else {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }

        timeoutRef.current = setTimeout(() => {
            callback();
            timeoutRef.current = null;
        }, delay);
    }
}
