import utils from 'utils';

import {useMemo, useState, useContext, useEffect, useCallback} from 'react';

import {Header, UiButton} from '@components/export';
import {useLabel} from '@/hooks/export';

import appContext from '@/appContext';

interface ErrorHandlerProps {
    error: Error,
    resetErrorBoundary: () => void,
}

export default function ErrorHandler({error}: ErrorHandlerProps) {
    const {currentUser} = useContext(appContext).userContext;
    const label = useLabel();

    const [visibleJson, setVisibleJson] = useState(false);
    const [errorSent, setErrorSent] = useState(false);
    const state = useMemo(() => {
        const logId = 'es' + Math.random().toString(16).slice(2).substring(0, 8);
        return {
            logId: logId,
            error: error.toString().replace(/'/g, '"'),
            stack: error.stack,
            message: error.message,
            url: window.location.href,
            user: currentUser?.id,
            browser: window.navigator.userAgent,
            resolution: window.screen.width + 'x' + window.screen.height,
            history: window.history,
            language: navigator.language,
            online: navigator.onLine,
            errorType: 'eshop-error',
        };
    }, [error, currentUser]);

    /**
     * logError to server
     */
    const logError = useCallback(async () => {
        try {
            await utils.post('system/logError', state);
        } catch (e) {
            console.error('ErrorHandler logError', e);
        }
        setErrorSent(true);
    }, [state]);

    useEffect(() => {
        if (!errorSent && state.error && state.user) {
            logError();
        }
    }, [state.error, state.user, logError, errorSent]);

    return (<>
        <Header/>

        <div className="page-content error-page">
            <div className="container">

                <div className={classMap.container}>
                    <div className={classMap.box}>
                        <p className={`${classMap.text} ${classMap.textBig}`}>
                            {label.errorCaught1}<br/>
                            {label.errorCaught2}
                            <span className={classMap.divider}></span>
                        </p>
                        <span className={`${classMap.text} ${classMap.textSmall}`}>
                            <span dangerouslySetInnerHTML={{__html: label.errorCaughtContent}}></span>
                            <br/>
                            <span onClick={() => setVisibleJson(!visibleJson)}>
                                Id: {state?.logId}<br/>
                            </span>

                            <pre className={classMap.jsonOutput + (visibleJson ? ' open' : '')}>{JSON.stringify(state, undefined, 4)}</pre>

                        </span>
                        <UiButton
                            onClick={() => {
                                window.location.reload();
                            }}
                            text={label.tryAgain}
                            style={2}
                        />
                    </div>
                </div>

            </div>
        </div>

        {/* <Footer/> */}
    </>);
}

const cmpClass = 'error-handler';
const classMap = utils.generateClassMap(cmpClass, {
    container: 'container container',
    box: 'box',
    text: 'text',
    textBig: 'text--big',
    textSmall: 'text--small',
    menuLink: 'menu-link',
    divider: 'divider',
    jsonOutput: 'json-output',
});
