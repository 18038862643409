import utils from 'utils';
import {useApiQuery} from '@/api';

import {useState, useContext, useEffect} from 'react';
import toast from 'react-hot-toast';
import {useQueryClient} from '@tanstack/react-query';
import {useNavigate} from 'react-router-dom';

import {
    BigTitle,
    CountInput,
    UiButton,
    ProductBadge,
    RenderImage,
    ProductStockInfo,
} from '@components/export';
import {useLabel} from '@/hooks/export';

import {Skeleton, useMediaQuery} from '@mui/material';

import appContext from '@/appContext';

import emptyImg from '@img/test1.jpg';

// todo: eslint
export const ProductDetail = (props: {
    id: string, // todo: create Product type
}) => {
    const queryClient = useQueryClient();
    const navigate = useNavigate();
    const label = useLabel();

    const {currentUser} = useContext(appContext).userContext;
    const cart = useContext(appContext).cartContext;
    const {filters, setFilters} = useContext(appContext).productsContext;

    const product: apiProduct | void = useApiQuery('getProduct', {
        id: props.id,
    }).data;

    const [loading, setLoading] = useState(false);

    const data = {
        categoryName: '',
        category: '',
        description: {__html: ''},
        img: [],
        isDiscount: false,
        name: '',
        priceCurrent: 0.00,
        pricePrev: 0.00,
        stock: 0,
        stockInfo: {}, // fixme: default data
        additionalServices: [],
        isOnSale: false,
    };

    const handle = {
        imgSelect: (_index: number) => {
            // console.log('imgSelect:36', index); // todo: remove!

            // todo: switch image in portrait
        },
        addToCart: () => {
            setLoading(true);

            Object.entries(data.additionalServices).map((item) => {
                data.additionalServices[item[0]].qty = 0;
            });

            utils.logEvent('cartEntry', {
                productId: props.id,
                qty: qty,
            });

            cart.updateItem(props.id, qty, data.additionalServices)
                .then(() => {
                    queryClient.invalidateQueries({queryKey: ['cart']});

                    toast.remove();
                    toast.success(label.cartIsUpToDate);

                    setLoading(false);
                });
        },
        notFound: () => {
            navigate('/catalog');
        },
    };

    if (product !== undefined && checkProduct(product)) {
        if (product === null) {
            handle.notFound();
        } else {
            data.categoryName = product.categoryName;
            data.category = product.category;
            data.description = {__html: product.description};
            data.img = product.images;
            data.isDiscount = product.isDiscount;
            data.name = product.name;
            data.priceCurrent = product.priceCurrent;
            data.pricePrev = product.pricePrev;
            data.stock = product.stock;
            data.stockInfo = product.stockInfo;
            data.additionalServices = product.additionalServices;
            data.isOnSale = product.isOnSale;
        }
    }

    const flags = {
        showPrevPrice: data.isDiscount || data.priceCurrent !== data.pricePrev,
        isLoading: !data.name,
        isDesktop: useMediaQuery('(min-width: 1024px)'),
    };

    const [qty, setQty] = useState(data.stock ? 1 : 0);

    useEffect(() => {
        // save product id to lastSeenProducts array local storage - limit array length to 10 items
        const lastSeenProducts = JSON.parse(localStorage.getItem('lastSeenProducts') || '{}');
        const userId = currentUser.id;

        if (!userId) {
            return;
        }

        if (lastSeenProducts[userId] === undefined) {
            lastSeenProducts[userId] = [];
        }

        if (!lastSeenProducts[userId].includes(props.id)) {
            if (lastSeenProducts[userId].length >= 20) { // store last 20 products in lastSeenProducts local storage
                lastSeenProducts[userId].shift();
            }
            lastSeenProducts[userId].push(props.id);
        } else {
            // move product id to the end of array
            lastSeenProducts[userId].push(lastSeenProducts[userId].splice(lastSeenProducts[userId].indexOf(props.id), 1)[0]);
        }

        localStorage.setItem('lastSeenProducts', JSON.stringify(lastSeenProducts));

        // invalidate getProducts query
        queryClient.invalidateQueries({queryKey: ['getProducts']});
    }, [queryClient, props.id, currentUser.id]);

    return <div className={cmpClass}>
        <div className={classMap.head}>
            <span className={classMap.bread}>
                <span
                    className={classMap.breadLink}
                    onClick={() => {
                        setFilters({
                            ...filters,
                            purpose: data.category,
                        });
                        navigate('/catalog');
                    }}
                >
                    {data.categoryName}
                </span>
                <span>{' > '}</span>
                <span>{data.name}</span>
            </span>
            <BigTitle title={data.name} dark={true} className={classMap.title} />
        </div>
        <div className={classMap.card}>
            <div className={classMap.images}>
                <div className={classMap.portrait}>
                    <div className={classMap.info}>
                        {
                            !flags.isLoading ?
                                <>
                                    <ProductBadge stock={data.stock} isDiscount={data.isDiscount}/>
                                    <ProductBadge onSale={data.isOnSale}/>
                                </> :
                                <></>
                        }
                    </div>
                    <a href={utils.getApiImgPath(data.img[0], 'preview')} target="_blank" rel="noreferrer">
                        {
                            !flags.isLoading ?
                                <RenderImage
                                    src={data.img[0] ? utils.getApiImgPath(data.img[0], 'large') : emptyImg}
                                    className={classMap.imagePreview}
                                    alt=""
                                /> :
                                <Skeleton width='100%' height='100%'/>
                        }
                    </a>
                </div>
                <div className={classMap.selector}>
                    {
                        !flags.isLoading ?
                            data.img.map((src, index) => {
                                return <RenderImage
                                    src={src ? utils.getApiImgPath(src, 'thumbnail') : emptyImg}
                                    className={classMap.imageThumbnailPreview}
                                    onClick={() => handle.imgSelect(index)}
                                    alt={data.name}
                                    key={src}
                                />;
                            }) :
                            <Skeleton width='100%' height='100%'/>
                    }
                </div>
            </div>
            <div className={classMap.detail}>
                <div className={classMap.description} dangerouslySetInnerHTML={data.description}></div>
                <div className={classMap.offer}>
                    <div className={classMap.offerBox}>
                        {
                            !flags.isLoading ?
                                <ProductStockInfo stockInfo={data.stockInfo} stock={data.stock}/> :
                                <></>
                        }
                    </div>
                    <div className={classMap.offerBox}>
                        <div className={classMap.price}>
                            {
                                flags.showPrevPrice ?
                                    <p className={classMap.pricePrev}>
                                        <p> {utils.formatPrice(data.pricePrev)} {label.currency}</p>
                                    </p> :
                                    <></>
                            }
                            <div className={classMap.priceCurrent}>
                                <span>{label.priceCurrentNoVat}</span>
                                {
                                    data.priceCurrent ?
                                        <p>{utils.formatPrice(data.priceCurrent) + ' ' + label.currency}</p> :
                                        <Skeleton />
                                }
                            </div>
                        </div>
                        <div className={classMap.action}>
                            <div className={classMap.actionRow}>
                                <CountInput
                                    value={qty}
                                    onChange={setQty}
                                    max={data.stock ? 99 : 1}
                                />
                                <UiButton
                                    style={1}
                                    text={label.addToCart}
                                    onClick={() => handle.addToCart()}
                                    disabled={!data.priceCurrent || !data.stock}
                                    loading={flags.isLoading || loading}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className={classMap.description} dangerouslySetInnerHTML={data.description}></div>
            </div>
        </div>
    </div>;
};

/**
 * is product var valid
 * @param product
 */
const checkProduct = (product: apiProduct | void): product is apiProduct => {
    return ![undefined].includes(product as apiProduct);
};

const cmpClass = 'product-detail';
const classMap = utils.generateClassMap(cmpClass, {
    action: 'action',
    actionRow: 'action-row',
    bread: 'bread',
    breadLink: 'bread-link',
    card: 'card',
    count: 'count',
    description: 'description',
    detail: 'detail',
    head: 'head',
    images: 'images',
    offer: 'offer',
    offerBox: 'offer-box',
    portrait: 'portrait',
    price: 'price',
    priceCurrent: 'price-current',
    priceEmpty: 'price-empty',
    pricePrev: 'price-prev',
    selected: 'selected',
    selector: 'selector',
    sale: 'sale',
    title: 'title',
    info: 'info',
    imagePreview: 'image-preview',
    imageThumbnailPreview: 'image-thumbnail-preview',
});
