import {useApiQuery} from '@/api';

import {useState, useEffect} from 'react';

export const useMachineFilter = (
    brand: string,
) => {
    const queryBrands = useApiQuery('getFiltersMachines', {});
    const queryModels = useApiQuery('getFiltersMachines', {brand}, !brand);

    const [brands, setBrands] = useState([]);
    const [models, setModels] = useState([]);

    if (queryBrands.data !== undefined && Array.isArray(queryBrands.data)) {
        if (!brands.length) {
            queryBrands.data.map((item) => {
                brands.push(item.brand);
            });

            setBrands([...brands]);
        }
    }

    if (queryModels.data !== undefined && Array.isArray(queryModels.data)) {
        if (!models.length && brand) {
            queryModels.data.map((item) => {
                models.push(item.model);
            });

            setModels([...models]);
        }
    }

    useEffect(() => {
        setModels([]);
    }, [brand]);

    return {
        brands: brands,
        models: models,
    }
};
