import {useEffect, useState} from 'react';

interface IBeforeInstallPromptEvent extends Event {
    readonly platforms: string[];
    readonly userChoice: Promise<{
        outcome: 'accepted' | 'dismissed';
        platform: string;
    }>;
    prompt(): Promise<void>;
}

export function useAddToHomescreenPrompt(): [
    IBeforeInstallPromptEvent | null,
    () => void,
] {
    const [prompt, setState] = useState<IBeforeInstallPromptEvent | null>(
        null,
    );

    const promptToInstall = () => {
        if (prompt) {
            return prompt.prompt();
        }
        return Promise.reject(
            new Error('Tried installing before browser sent "beforeinstallprompt" event'),
        );
    };

    useEffect(() => {
        const ready = (e: IBeforeInstallPromptEvent) => {
            e.preventDefault();
            setState(e);
        };

        window.addEventListener('beforeinstallprompt', ready as any);

        return () => {
            window.removeEventListener('beforeinstallprompt', ready as any);
        };
    }, []);

    const isInstalledPWA = window.matchMedia('(display-mode: window-controls-overlay)').matches ||
                       window.matchMedia('(display-mode: standalone)').matches;

    const isAndroid = navigator.userAgent.toLowerCase().indexOf('android') > -1;

    if (isInstalledPWA || !isAndroid) {
        return [null, promptToInstall];
    }

    return [prompt, promptToInstall];
}
