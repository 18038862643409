import {useContext, useEffect} from 'react';
import {useParams} from 'react-router-dom';

import {
    Footer,
    Header,
    ProductAttrs,
    ProductDetail,
    ProductsDisplayManager,
} from '@components/export';
import appContext from '@/appContext.tsx';

export const ProductPage = () => {
    const productId = useParams().id;

    const {setMachine} = useContext(appContext).productsContext;
    // todo: move render data to here -> pass as props

    useEffect(() => {
        setMachine({
            brand: '',
            model: '',
            purpose: '',
        });
    }, []);

    return <>
        <Header/>

        <div className="page-content product-page">
            <div className="product-page-product container">
                <ProductDetail id={productId}/>
                <ProductAttrs id={productId}/>
            </div>

            <div className="product-page-products container">
                <ProductsDisplayManager limit={10} isDemo={true} mode={'alternative'} alternativeTo={productId} />
            </div>
        </div>

        <Footer/>
    </>;
};
