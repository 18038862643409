import {TextField} from '@mui/material';
import {AsYouType} from 'libphonenumber-js';

export const UiPhoneInput = (props: {
    name?: string,
    value: string,
    label: string,
    onChange: (value: any) => void,
    onBlur?: any,
    required?: boolean,
    error?: boolean,
}) => {
    // todo: validate phone here, return via prop

    return <TextField
        name={props.name}
        label={props.label}
        size={'small'}
        value={props.value}
        fullWidth
        onChange={(e) => props.onChange(applyPhoneFormat(e.target.value))}
        onBlur={() => props.onBlur ? props.onBlur() : undefined}
        required={props.required}
        error={props.error}
    />;
};

const applyPhoneFormat = (oldPhone: string) => {
    return new AsYouType('CZ').input(oldPhone);
};
