import {useApiQuery} from '@/api';

import {ServiceOrderListItem} from '@components/export';
import {useLabel} from '@/hooks/export';

import {Skeleton} from '@mui/material';

export const ServiceOrderList = (props: {
    limit: number,
    offset: number,
}) => {
    const label = useLabel();

    const invoices = useApiQuery('getServiceOrders', {
        limit: props?.limit ?? 5,
        offset: props?.offset ?? 0,
    }).data;

    const handle = {
        invoicePrint(id: string) {
            const apiEndpoint = (window as any).config.app.api;
            const url = `${apiEndpoint}service-orders/print/${id}`;

            window.open(url, '_blank');
        },
    };

    return <div className={cmpClass}>
        {
            !checkInvoices(invoices) ?
                <Skeleton
                    height={'100%'}
                    variant={'rectangular'}
                    className={'cart-list-item'}
                /> :
                invoices.length ?
                    invoices.map((invoice) => {
                        return (
                            <ServiceOrderListItem
                                key={invoice.id}
                                invoice={invoice}
                                label={label}
                                handle={handle}
                            />
                        );
                    }) :
                    <p>{label.noInvoices}</p>
        }
    </div>;
};

/**
 * are invoices var valid
 * @param {array} invoices
 * @returns {boolean}
 */
const checkInvoices = (invoices: apiInvoice[] | void): invoices is apiInvoice[] => {
    return ![undefined, null].includes(invoices as apiInvoice[]);
};

const cmpClass = 'service-order-list';
