import {useNavigate} from 'react-router-dom';
import {useContext} from 'react';
import appContext from '@/appContext.tsx';

export const HomePage = () => {
    const navigate = useNavigate();
    const {currentUser} = useContext(appContext).userContext;

    if (currentUser.id) {
        navigate('/welcome');
    } else {
        navigate('/login');
    }

    return <></>;
};
