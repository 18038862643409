import utils from 'utils';

import {useNavigate} from 'react-router-dom';
import {useContext} from 'react';

import {
    ProductCard,
    UiButton,
} from '@components/export';
import {useLabel} from '@/hooks/export';

import {Skeleton} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';

import appContext from '@/appContext';

export const ProductsGrid = (props: {
    pageSize: number,
    mode: 'catalog' | 'recommended' | 'alternative', // fixme remove
}) => {
    const label = useLabel();
    const navigate = useNavigate();
    const isDesktop = useMediaQuery('(min-width: 1024px)');

    const {searchRes} = useContext(appContext).productsContext;

    const data = {
        productsCount: Array.isArray(searchRes?.pages) && searchRes.pages.at(-1).products.length,
    };

    const flags = {
        noProducts: !searchRes?.pages[0].products.length,
        showMore: data.productsCount >= props.pageSize,
        isScrollable:
            props.mode === 'alternative' &&
            (isDesktop ? data.productsCount > 4 : data.productsCount > 2),
    };

    const wrapperClass = classMap.wrapper + (flags.isScrollable ? ' ' + classMap.wrapperScrollable : ' ');

    return <div className={cmpClass}>
        {
            searchRes === undefined ?
                <div className={wrapperClass}>
                    {
                        [...Array(props.pageSize)].map((_e, i) => <Skeleton key={i} height={'100%'} variant={'rectangular'}/>)
                    }
                </div> :
                !flags.noProducts ?
                    <div className={wrapperClass}>
                        {
                            searchRes.pages.map((page, pageKey) => {
                                return page.products
                                    .slice(0, props.pageSize)
                                    .map((product, key) => <ProductCard product={product} key={pageKey + key}/>);
                            })
                        }
                    </div> :
                    <div className={classMap.notFound}>
                        <p>{label.offerFillMachineRequest}</p>
                        <UiButton
                            className={classMap.notFoundBtn}
                            style={1}
                            text={label.requestForProduct}
                            onClick={() => navigate('/machine-request')}
                        />
                    </div>
        }
    </div>;
};

const cmpClass = 'products-grid';
const classMap = utils.generateClassMap(cmpClass, {
    wrapper: 'wrapper',
    wrapperScrollable: 'wrapper--scrollable',
    notFound: 'not-found',
    notFoundBtn: 'not-found-btn',
});
