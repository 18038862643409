import utils from 'utils';

import {useContext, useRef, useEffect} from 'react';

// import {useLabel} from '@/hooks/export';

import appContext from '@/appContext';

export const ProductSearch = () => {
    // const label = useLabel();

    const {filters, setFilters} = useContext(appContext).productsContext;

    const inputRef = useRef(null);
    const timeoutRef = useRef(null);

    const searchPlaceholderText = 'např.: 12165 (12-16.5)';

    const handle = {
        inputChange: (value: string) => {
            utils.doWithTimeout(() => {
                setFilters({
                    ...filters,
                    name: value,
                    offset: 0,
                    // purpose: "", // reset purpose on search input change
                });
            }, 500, timeoutRef);
        },
    };

    useEffect(() => {
        if (filters.name !== undefined) {
            inputRef.current.value = filters.name;
        }
    }, [filters.name]);

    return <div className={cmpClass}>
        <input
            onChange={(e) => handle.inputChange(e.target.value)}
            // placeholder={label.searchProducts + '...'}
            placeholder={searchPlaceholderText}
            type="text"
            className={classMap.input}
            defaultValue={filters.name}
            ref={inputRef}
            // autoFocus
        />
        <i className={classMap.iconSearch}></i>
    </div>;
};

const cmpClass = 'product-search';
const classMap = utils.generateClassMap(cmpClass, {
    input: 'input',
    iconSearch: 'icon iconfa-search',
});
