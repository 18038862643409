import {useContext} from 'react';
import {useNavigate} from 'react-router-dom';

import {
    BigTitle,
    CatalogInfo,
    CategoriesGrid,
    Footer,
    Header,
    WelcomeBanner,
    PwaInstallPrompt,
    ProductsDisplayManager,
} from '@components/export';
import {useEshopConfig, useLabel} from '@/hooks/export';

import appContext from '@/appContext.tsx';

export const WelcomePage = () => {
    const label = useLabel();
    const navigate = useNavigate();

    const {menu} = useContext(appContext).catalogContext;

    const images = useEshopConfig().categoryImgMap;

    const options = Array.isArray(menu) ?
        menu.map((option) => {
            return {name: option.name, label: option.label, image: images[option.name]};
        }) :
        [{name: '', label: '', image: ''}];

    const {filters, setFilters} = useContext(appContext).productsContext;

    return <>
        <Header/>
        <PwaInstallPrompt />

        <div className="page-content welcome-page">
            <WelcomeBanner/>

            <div className="welcome-categories container">
                <BigTitle title={label.categories} dark={true} className={''}/>
                <CategoriesGrid options={options} onClick={(name) => {
                    navigate('/catalog');
                    setFilters({
                        ...filters,
                        purpose: name,
                    });
                }}/>
            </div>

            <div className="welcome-products container">
                <BigTitle title={label.recommendedProducts} dark={true} className={''}/>
                <ProductsDisplayManager limit={4} isDemo={true} mode={'recommended'}/>
            </div>

            <CatalogInfo/>
        </div>

        <Footer/>
    </>;
};
