import utils from 'utils';

import {useLabel} from '@/hooks/export';

export const ProductBadge = (props: {
    stock?: number,
    isDiscount?: boolean
    hideStock?: boolean,
    hideDiscount?: boolean,
    onSale?: boolean,
}) => {
    const label = useLabel();

    return <div className={cmpClass}>
        {
            !props.hideStock && props.stock !== undefined ?
                <div className={
                    props.stock ? (props.stock > 4 ? classMap.stock : classMap.limitedStock) : classMap.noStock
                }>
                    <div className={classMap.iconStock}><i className='iconfas-circle'></i></div>
                    <span>
                        {
                            props.stock ? (props.stock > 4 ? label.stock : label.limitedStock) : label.noStock
                        }
                    </span>
                </div> :
                <></>
        }
        {
            props.isDiscount && !props.hideDiscount ?
                <div className={classMap.sale}>
                    <span>{label.sale}</span>
                </div> :
                <></>
        }
        {
            props.onSale ?
                <div className={classMap.onSale}>
                    <span>{label.onSale}</span>
                </div> :
                <></>
        }
    </div>;
};

const cmpClass = 'product-badge';

const classMap = utils.generateClassMap(cmpClass, {
    iconStock: 'icon-stock',
    stock: 'stock',
    noStock: 'no-stock',
    limitedStock: 'limited-stock',
    sale: 'sale',
    onSale: 'on-sale',
});
