/**
 * getApiImgPath - get full path to image from API
 * @param {string} path
 * @param {string} size
 * @returns {string}
 */
const getApiImgPath = (
    path: string,
    size: string = 'original',
): string => {
    const apiEndpoint = (window as any).config.app.files;

    return `${apiEndpoint}eshopImage/${path}/${size}`;
};

export default getApiImgPath;
