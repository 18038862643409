import utils from 'utils';
import {api} from '@/api';

import {useState, useEffect} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {useQueryClient} from '@tanstack/react-query';
import toast from 'react-hot-toast';

import {useLabel} from '@/hooks/export';
import {BigTitle, UiButton} from '@components/export';

import {TextField} from '@mui/material';

export const UserForm = (props: {
    type: string,
}) => {
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const {token} = useParams();
    const label = useLabel();

    const [fields, setFields] = useState({});
    const [loading, setLoading] = useState(false);

    // fixme?
    // todo: validate are password same
    const data = {
        requestHref: '/request',
        form: {
            login: {
                label: {
                    button: label.doLogin,
                    link: label.forgotPass,
                    title: label.login,
                },
                data: {
                    href: '/reset-password',
                    fields: [
                        {type: 'email', label: label.email, name: 'email'},
                        {type: 'password', label: label.password, name: 'password'},
                    ],
                    name: 'login',
                },
            },
            forgot: {
                label: {
                    button: label.sendRequest,
                    link: label.backToLogin,
                    title: label.forgotPass,
                },
                data: {
                    href: '/login',
                    fields: [
                        {type: 'email', label: label.email, name: 'email'},
                    ],
                    name: 'forgot',
                },
            },
            reset: {
                label: {
                    button: label.resetPass,
                    link: label.backToLogin,
                    title: label.newPass,
                },
                data: {
                    href: '/login',
                    fields: [
                        {type: 'password', label: label.password, name: 'password'},
                        {type: 'password', label: label.passwordRepeat, name: 'password_repeat'},
                    ],
                    name: 'reset',
                },
            },
        },
    };

    const handle = {
        async sendForm(name: string) {
            setLoading(true);

            if (name === 'login') {
                await this.userLogin();
            }
            // if (name === 'register') {
            //     await this.userRegister();
            // }
            if (name === 'forgot') {
                await this.userForgotPass();
            }
            if (name === 'reset') {
                await this.userResetPass();
            }

            setLoading(false);
        },
        inputChange(value: string, name: string) {
            fields[name] = value;
            setFields({...fields});
        },
        userLogin() {
            return api.user.login(fields)
                .then((response) => {
                    if (response.code === 200) {
                        queryClient
                            .invalidateQueries({queryKey: ['user']})
                            .then(() => navigate('/welcome'));
                    } else {
                        toast.error(label.loginErr);
                    }
                });
        },
        userForgotPass() {
            return api.user.forgotPass(fields['email'])
                .then((ret) => {
                    if(!ret?.status) {
                        toast.error(label[ret?.message]);
                        return;
                    }
                    toast.success(label.accepted);
                    navigate('/login');
                });
        },
        userResetPass() {
            return api.user.resetPass({
                password: fields['password'],
                token,
            })
                .then(() => {
                    toast.success(label.passwordWasSet);
                    navigate('/login');
                });
        },
        checkToken() {
            return api.user.checkToken(token)
                .then((response) => {
                    if (response.code !== 200) {
                        navigate('/login');
                    }
                });
        },
    };

    const form = data.form[props.type];

    useEffect(() => {
        data.form[props.type].data.fields.map((field) => {
            fields[field.name] = '';
        });

        setFields({...fields});
    }, [props.type]);

    useEffect(() => {
        if (props.type === 'reset') {
            handle.checkToken();
        }
    }, []);

    return <div className={cmpClass}>
        <BigTitle title={form.label.title} dark={true} className={classMap.title}/>
        <div className={classMap.box}>
            <div className={classMap.fields}>
                {form.data.fields.map((field: {
                    label: string,
                    name: string,
                    type: string,
                    required?: boolean,
                }) => {
                    return <TextField
                        onChange={(e) => handle.inputChange(e.target.value, field.name)}
                        className={classMap.field}
                        key={field.name}
                        name={field.name}
                        type={field.type}
                        label={field.label}
                        value={fields[field.name]}
                        size={'small'}
                        required={field.required}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                handle.sendForm(form.data.name);
                            }
                        }}
                    />;
                })}
            </div>
            <div className={classMap.action}>
                <div className={classMap.button}>
                    <UiButton
                        style={1}
                        text={form.label.button}
                        onClick={() => handle.sendForm(form.data.name)}
                        loading={loading}
                    />
                </div>
                <div className={classMap.link}>
                    <a onClick={() => navigate(form.data.href)}>
                        {form.label.link}
                    </a>
                </div>
            </div>
        </div>
        <div className={classMap.request}>
            <a onClick={() => navigate(data.requestHref)}>
                {label.requestTitle}
            </a>
        </div>
    </div>;
};

const cmpClass = 'user-form';
const classMap = utils.generateClassMap(cmpClass, {
    title: 'title',
    box: 'box',
    fields: 'fields',
    field: 'field',
    action: 'action',
    button: 'button',
    link: 'link',
    request: 'request',
});
