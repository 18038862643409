import utils from 'utils';

import {forwardRef} from 'react';

export const UiButton = forwardRef<HTMLButtonElement, any>((props: {
    style: number,
    text: string | JSX.Element,
    onClick: () => void,
    disabled?: boolean,
    icon?: string,
    loading?: boolean,
}, ref) => {
    const {onClick, style, icon, loading, text, disabled, ...rest} = props;
    const styleVariant = 'style' + style;

    const attrs = {};

    if (disabled) {
        attrs['disabled'] = true;
    }

    return <button
        onClick={() => props.onClick()}
        ref={ref}
        {...rest}
        {...attrs}
        className={cmpClass + ' ' + classMap[styleVariant] + (icon ? ' ' + classMap.hasIcon : '')}
    >
        {props.icon ? <i className={`${icon} ${classMap.icon}`}></i> : ''}
        {
            props.loading ?
                <span className={classMap.hidden}>{text}</span> :
                <span>{text}</span>
        }
        {
            props.loading ?
                <i className={classMap.iconLoading}></i> :
                <></>
        }
    </button>;
});

const cmpClass = 'ui-button';
const classMap = utils.generateClassMap(cmpClass, {
    style1: 'style-1',
    style2: 'style-2',
    icon: 'icon',
    hidden: 'hidden',
    hasIcon: 'has-icon',
    iconLoading: 'icon-loading iconfas-spinner',
});
