import utils from 'utils';
import emptyImg from '@img/test1.jpg';

export const OrderLineListItem = (props: {
    handle: any,
    label: any,
    line: any,
}) => {
    const {handle, label, line} = props;

    const priceByQty = line.price.current * line.qty;

    const formattedPrice = {
        current: utils.formatPrice(line.price.current),
        prev: utils.formatPrice(line.price.prev),
        byQty: utils.formatPrice(priceByQty),
    };

    return (
        <div className={cmpClass}>
            <div className={classMap.row} onClick={() => handle.navigateToProduct(line.productTemplateId)}>

                <div className={classMap.img}><img src={line.img ? utils.getApiImgPath(line.img, 'small') : emptyImg} alt=""/></div>
                <div className={classMap.product}>
                    <div className={classMap.title}>{line.title}</div>
                </div>

                <div className={classMap.box}>
                    <div className={classMap.price}>
                        <span className={classMap.priceCurrent}>
                            <span>
                                {
                                    (line.qty <= 1 ? formattedPrice.current : formattedPrice.byQty) + ' ' + label.currency
                                }
                            </span>
                            {
                                line.qty > 1 ?
                                    <span className={'secondary'}>
                                        {'(' + formattedPrice.current + ' ' + label.currency + ' x ' + line.qty + label.qty + ')'}
                                    </span> :
                                    <></>
                            }
                        </span>
                    </div>
                </div>
            </div>
        </div>);
};

const cmpClass = 'order-line-list-item';
const classMap = utils.generateClassMap(cmpClass, {
    box: 'box',
    title: 'title',
    price: 'price',
    product: 'product',
    priceCurrent: 'price-current',
    row: 'row',
    created: 'created',
    createdBy: 'created-by',
    orderNum: 'order-num',
    boxBadge: 'box-badge',
    img: 'img',
});
