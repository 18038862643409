// stackoverflow - 17542108
export default function sortAlphabetCzech(
    s1: string,
    s2: string,
) {
    const charMapL = ' 0123456789aábcčdďeéěfghiíjklmnňoópqrřsštťuúůvwxyýzž';
    const charMapU = ' 0123456789AÁBCČDĎEÉĚFGHIÍJKLMNŇOÓPQRŘSŠTŤUÚŮVWXYÝZŽ';
    const charsOrder = {};

    for (const i in charMapL.split('')) {
        charsOrder[charMapL[i]] = parseInt(i);
        charsOrder[charMapU[i]] = parseInt(i);
    }

    const mySort = (s1, s2) => {
        let idx = 0;
        while ((idx < s1.length) && (idx < s2.length) && (charsOrder[s1[idx]] === charsOrder[s2[idx]])) {
            idx++;
        }
        if ((idx === s1.length) && (idx === s2.length)) return 0;
        if (idx === s1.length) return 1;
        if (idx === s2.length) return -1;
        return charsOrder[s1[idx]] > charsOrder[s2[idx]] ? 1 : (charsOrder[s1[idx]] < charsOrder[s2[idx]] ? -1 : 0);
    };

    return mySort(s1 || '', s2 || '');
}
