import utils from 'utils';

import {useContext} from 'react';

import {UiBadge, ProductsGridSort} from '@components/export';
import {useLabel} from '@/hooks/export';
import appContext from '@/appContext.tsx';

export const ProductsControlBar = (props: {
    handle: any,
}) => {
    const label = useLabel();

    const {filters} = useContext(appContext).productsContext;

    return <div className={cmpClass}>
        <div className={classMap.box}>
            <span className={classMap.title}>
                {label.searchResults}
                <div className={classMap.selectedFilters}>
                    {
                        filters.attributes !== undefined && filters.attributes.length ?
                            filters.attributes.map((item, index) => {
                                return <UiBadge
                                    title={getAttributeLabelByName(item.name) + ': ' + item.value}
                                    onClick={() => props.handle.selectFilter(item.name, item.value)}
                                    key={index}
                                />;
                            }) :
                            <></>
                    }
                </div>
            </span>
            {
                (filters.attributes !== undefined && filters.attributes.length) || filters.name || filters.purpose ?
                    <span
                        onClick={() => props.handle.cleanFilters()}
                        className={classMap.cleanFilters}
                    >
                        <><i className={'iconfa-close'}></i>{label.cancelFilters}</>
                    </span> :
                    <></>
            }
        </div>
        <ProductsGridSort/>
    </div>;
};

const cmpClass = 'products-control-bar';
const classMap = utils.generateClassMap(cmpClass, {
    box: 'box',
    cleanFilters: 'clean-filters',
    title: 'title',
    selectedFilters: 'selected-filters',
});

const getAttributeLabelByName = (
    name: string,
) => {
    // fixme! dehardcode - get this data from api, but not directly
    //  - write it to context somewhere before - thus this method will not be needed

    // ? write to local state

    const attrLabelMap = {
        'Typ_pneu': 'Typ pneu',
        'Druh_stroje': 'Druh stroje',
        'Brand': 'Výrobce',
        'Prumer_rafku': 'Průměr ráfku',
        'Rozmer_pneu': 'Rozměr pneu',
        'Rim_pocet_platen': 'RIM / Počet pláten',
        'Pocet_platen': 'Počet pláten',
        'Pocet_platen_index': 'Počet pláten/Index',
        'Profil_kola': 'Profil kola',
        'Prumer_kola': 'Průměr kola',
        'Sirka_kola': 'Šířka kola',
        'Nosnost': 'Nosnost',
        'Sirka': 'Šířka',
        'Delka': 'Délka',
        'Trida_iso': 'Třída ISO',
        'Tloustka': 'Tloušťka',
        'Typ_zajisteni': 'Typ zajištění',
    };

    return attrLabelMap[name];
};
