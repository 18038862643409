import {api} from '@/api';
import utils from 'utils';

import {useContext} from 'react';
import {useQueryClient} from '@tanstack/react-query';
import {useNavigate} from 'react-router-dom';

import {UiPopover, HeaderSalesContact} from '@/components/export';
import {useLabel, useUiPopover} from '@/hooks/export';

import {
    List,
    ListItem,
    ListItemButton,
    ListItemText,
} from '@mui/material';

import appContext from '@/appContext';

export const HeaderTop = () => {
    const queryClient = useQueryClient();
    const navigate = useNavigate();
    const label = useLabel();

    const {currentUser} = useContext(appContext).userContext;

    const handle = {
        userLogout() {
            api.user.logout()
                .then(() => queryClient.invalidateQueries({queryKey: ['user']}));
        },
    };

    const popoverData = {
        sales: useUiPopover(),
        user: useUiPopover(),
    };

    // todo: move static values to config
    const data = {
        phone: '+420 596 013 541',
        phoneLink: 'tel:+420596013541',
        companyName: 'ČEMAT trading, spol. s r.o.',
        list: [
            {name: 'profile', label: label.myAccount, onClick: () => navigate('/profile')},
            {name: 'orders', label: label.myOrders, onClick: () => navigate('/orders')},
            {name: 'logout', label: label.logout, onClick: () => handle.userLogout()},
        ],
    };

    return <div className={cmpClass}>
        <div className={classMap.container}>
            <div className={classMap.title}>
                {data.companyName}
            </div>
            <div className={classMap.menu}>
                <div className={classMap.menuItem}>
                    <a className={classMap.menuLink} href={data.phoneLink}>
                        <i className={classMap.menuIconPhone}></i>
                        {data.phone}
                    </a>
                </div>
                <div className={classMap.menuItem}>
                    <a className={classMap.menuLink} onClick={popoverData.sales.handleClick}>
                        <i className={classMap.menuIconInfo}></i>
                        {label.helpAndSupport}
                    </a>
                </div>
                <div className={classMap.menuItem + (!currentUser.id ? ' ' + classMap.menuItemHidden : '')}>
                    <a className={classMap.menuLink + ' ' + classMap.userMenu} onClick={popoverData.user.handleClick}>
                        <i className={classMap.menuIconUser}></i>
                        {
                            currentUser.id ?
                                currentUser.firstName + ' ' + currentUser.lastName :
                                ''
                        }
                        <i className={'icon iconfa-arrow' + (popoverData.user.anchorEl ? 'Up' : 'Down')}></i>
                    </a>
                </div>
            </div>
        </div>
        {currentUser?.salesContact &&
            <UiPopover
                anchorEl={popoverData.sales.anchorEl}
                setAnchorEl={popoverData.sales.setAnchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <HeaderSalesContact />
            </UiPopover>
        }
        <UiPopover
            anchorEl={popoverData.user.anchorEl}
            setAnchorEl={popoverData.user.setAnchorEl}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
        >
            <List>
                {
                    data.list.map((item) => {
                        return <ListItem disablePadding key={item.name}>
                            <ListItemButton
                                onClick={() => {
                                    item.onClick();
                                    popoverData.user.handleClose();
                                }}
                            >
                                <ListItemText primary={item.label}/>
                            </ListItemButton>
                        </ListItem>;
                    })
                }
            </List>
        </UiPopover>
    </div>;
};

const cmpClass = 'header-top';
const classMap = utils.generateClassMap(cmpClass, {
    container: 'container container',
    title: 'title',
    menu: 'menu',
    menuItem: 'menu-item',
    menuItemHidden: 'menu-item--hidden',
    menuLink: 'menu-link',
    menuIconPhone: 'menu-icon iconfa-phone',
    menuIconInfo: 'menu-icon iconfa-info',
    menuIconUser: 'menu-icon iconfa-user',
    salesContactBox: 'sales-contact-box',
    userMenu: 'user-menu',
});
