import utils from 'utils';

import {useContext} from 'react';

import {UiButton, RenderImage} from '@components/export';
import {useLabel} from '@/hooks/export';

import appContext from '@/appContext.tsx';

// todo: move to config
import emptyImg from '@img/empty_user_profile.png';

export const HeaderSalesContact = () => {
    const {currentUser} = useContext(appContext).userContext;
    const label = useLabel();

    return <div className={cmpClass}>
        <div className={classMap.box}>
            <p className={classMap.text + ' ' + classMap.textBig}>
                {label.headerSalesContact}
                <span className={classMap.divider}></span>
            </p>
            <div className={classMap.content}>
                {
                    currentUser.salesContact?.profile_photo ? (
                        <RenderImage
                            src={utils.getApiImgPath(currentUser.salesContact.profile_photo, 'small')}
                            alt={label.headerSalesContact}
                            containerClassName={classMap.imgWrapper}
                            className={classMap.img}
                        />
                    ) : (
                        <div className={classMap.imgWrapper}>
                            <img src={emptyImg} alt={label.headerSalesContact} className={classMap.img}/>
                        </div>
                    )
                }
                <div className={classMap.textWrapper}>
                    <p className={classMap.text + ' bold'}>
                        {currentUser.salesContact.first_name} {currentUser.salesContact.last_name}
                    </p>
                    <a
                        href={`mailto:${currentUser.salesContact.email}`}
                        className={classMap.text + ' link'}
                    >
                        <span className='iconfa-email icon'/>
                        {currentUser.salesContact.email}
                    </a>
                    <a
                        href={`tel:${currentUser.salesContact.phone_mobile}`}
                        className={classMap.text + ' link'}
                    >
                        <span className='iconfa-phone icon'/>
                        {currentUser.salesContact.phone_mobile}
                    </a>
                </div>
                <div className={classMap.buttons}>
                    <UiButton
                        style={1}
                        text={label.headerSalesContactButton}
                        onClick={() => window.open(`mailto:${currentUser.salesContact.email}`)}
                    />
                </div>
            </div>
        </div>
    </div>;
};

const cmpClass = 'header-sales-contact';
const classMap = utils.generateClassMap(cmpClass, {
    box: 'box',
    text: 'text',
    textBig: 'text--big',
    textWrapper: 'text-wrapper', // fixme
    divider: 'divider',
    content: 'content',
    img: 'img',
    imgWrapper: 'img-wrapper', // fixme
    buttons: 'buttons',
});
