import utils from 'utils';
import {api} from '@/api';

import {useEffect, useRef, useState} from 'react';
import {useNavigate} from 'react-router';
import {toast} from 'react-hot-toast';
import {useMediaQuery} from '@mui/material';

import {useLabel} from '@/hooks/export';
import {MachinePartSelect, UiButton, UiSelect} from '@components/export';

import {TextField} from '@mui/material';

export const MachineRequestForm = () => {
    const label = useLabel();
    const navigate = useNavigate();
    const mediaQuery = useMediaQuery('(min-width: 768px)');

    const [message, setMessage] = useState('');
    const [attachments, setAttachments] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    // todo? redundant
    const [upload, setUpload] = useState({
        file: null,
        purpose: '',
    });

    const purposeOptions = [
        {value: '', label: `-- ${label.empty} --`},
        {value: 'frontWheel', label: 'Přední kolo'},
        {value: 'backWheel', label: 'Zadní kolo'},
        {value: 'label', label: 'Štítek'},
        {value: 'whole', label: 'Celek'},
    ];

    const uploadInputRef = useRef(null);

    const handle = {
        send() {
            setIsLoading(true);

            const formData = new FormData();

            attachments.map((attachment, i) => {
                formData.append(`attachments[${i}]`, attachment.file);
                formData.append(`attachmentsInfo[${i}][purpose]`, attachment.purpose);
            });

            formData.append('message', message);

            api.machine.request(formData)
                .then((response) => {
                    navigate('/catalog');
                    toast.success(label.accepted);

                    console.log(':38', response); // todo: remove!
                });
        },
        upload() {
            // todo? redundant

            setAttachments([
                ...attachments,
                upload,
            ]);

            setUpload({
                file: null,
                purpose: '',
            });

            uploadInputRef.current.value = '';
        },
    };

    useEffect(() => {
        if (upload.file) {
            handle.upload();
        }
    }, [upload]);

    // todo: split - too much for single cmp
    return <div className={cmpClass}>
        <div className={classMap.wrapper}>
            <TextField
                className={classMap.message}
                label={label.message}
                size={'small'}
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                multiline
                maxRows={mediaQuery ? 16 : 6}
                minRows={mediaQuery ? 16 : 6}
                fullWidth
                required={true}
                slotProps={{
                    input: {
                        sx: {
                            height: '100%',
                            alignItems: 'start',
                        },
                    },
                }}
                // error={!message}
            />

            <div className={classMap.box}>
                <MachinePartSelect
                    onPartSelect={(partName) => {
                        setUpload({
                            file: null, purpose: partName,
                        });

                        uploadInputRef.current.click();
                    }}
                />

                <div className={classMap.upload}>
                    <div className={classMap.selectImg}>
                        <UiButton
                            style={1}
                            text={upload.file ? upload.file.name : label.uploadImage}
                            onClick={() => {
                                uploadInputRef.current.click();
                            }}
                        />
                        <input
                            type="file"
                            ref={uploadInputRef}
                            accept={'image/png, image/jpeg'} // or use image/* in case of troubles
                            onChange={(e) => {
                                setUpload({
                                    ...upload, file: e.target.files[0],
                                });
                            }}
                        />
                    </div>
                    <UiSelect
                        name={'urceni'}
                        label={label.purpose}
                        value={upload.purpose}
                        options={purposeOptions}
                        onChange={(value) => {
                            setUpload({
                                ...upload, purpose: value.toString(),
                            });
                        }}
                    />
                </div>
            </div>
        </div>

        <div className={classMap.uploadList}>
            {
                attachments.length ?
                    attachments.map((attachment, index) => {
                        return <div
                            className={classMap.attachment}
                            key={index}
                        >
                            <div className={classMap.image}>
                                <img
                                    src={URL.createObjectURL(attachment.file)}
                                    alt={attachment.file.name}
                                />
                                <span className={classMap.imageInfo}>{formatFileSize(attachment.file.size)}</span>
                            </div>
                            <span>{attachment.file.name}</span>
                            <span>
                                {
                                    attachment.purpose ?
                                        purposeOptions.find((v) => v.value === attachment.purpose).label :
                                        ''
                                }
                            </span>
                            <button
                                onClick={() => setAttachments(
                                    attachments.filter((_, i) => i !== index),
                                )}
                            >
                                <i className={'iconfa-close'}></i>
                            </button>
                        </div>;
                    }) :
                    <span className={classMap.attachment}>
                        {label.noAttachments}
                    </span>
            }
        </div>

        <div className={classMap.submit}>
            <UiButton
                style={1}
                text={label.sendRequest}
                onClick={() => handle.send()}
                loading={isLoading}
                disabled={!checkMessage(message)}
            />
        </div>
    </div>;
};

const cmpClass = 'machine-request-form';
const classMap = utils.generateClassMap(cmpClass, {
    box: 'box',
    upload: 'upload',
    uploadList: 'upload-list',
    attachment: 'attachment',
    message: 'message',
    image: 'image',
    imageInfo: 'image-info',
    submit: 'submit',
    selectImg: 'select-img',
    wrapper: 'wrapper',
});

const formatFileSize = (size) => {
    if (size < 1024) {
        return `${size} bytes`;
    } else if (size < 1048576) {
        return `${(size / 1024).toFixed(2)} KB`;
    } else {
        return `${(size / 1048576).toFixed(2)} MB`;
    }
};

const checkMessage = (message: string) => {
    const regex = /[^\s]/;

    return regex.test(message);
}
